export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
  icon: {
    height: 50,
    objectFit: 'contain',
  },
  green: {
    color: theme.palette.green.main,
  },
  white: {
    color: theme.palette.white.main,
  },
  greenBackground: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    [theme.breakpoints.down('md')]: {
      padding: '70px 40px',
    },
  },
  greenTextSection: {
    padding: theme.spacing(8),
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    '& img': {
      height: 40,
    },
    '& em': {
      fontStyle: 'italic',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 !important',
      paddingBottom: '100px !important',
    },
  },
  left: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(32),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(16),
    },
    '& img': {
      position: 'absolute',
      width: 'calc(100% + 50px)',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '-75px',
      },
    },
  },
  right: {
    position: 'relative',
    '& img': {
      position: 'absolute',
      width: '120%',
      right: 0,
      top: '-30px',
      [theme.breakpoints.down('md')]: {
        marginTop: '0',
        position: 'relative',
        width: '100%',
      },
    },
  },
  bottomContent: {
    '& b': {
      textDecoration: 'underline',
    },
  },
  illustration: {
    width: '70%',
    display: 'block',
    paddingTop: theme.spacing(4),
    margin: 'auto !important',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  interregImgWeb: {
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
});
