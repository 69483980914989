export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
    '& .dash': {
      color: theme.palette.orange.light,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  icon: {
    width: 76,
    height: 80,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: 55,
      height: 55,
    },
  },
  image: {
    width: '50%',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  color: {
    color: theme.palette.orange.main,
  },
  colorBackground: {
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.contrastText,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  countryBloc: {
    border: '1px solid white',
    '& > div': {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
  },
});
