import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PopupPage from '../PopupPage';
import TypoFramed from '../../components/TypoFramed';

import styles from './styles';

class PopupCEO extends React.Component {
  render() {
    const { popupData, classes, color, open, onClose, baseUrl } = this.props;
    const imgSrc = `${baseUrl}/popups/${parse(popupData.imgSrc)}`;
    return (
      <PopupPage open={open} onClose={onClose} closeIconColor="ocher">
        <div className={classes.container}>
          <div>
            <img key="img" src={imgSrc} alt="" className={classes.image} />
          </div>
          <div className={`${classes.asideBlock}`}>
            <Typography variant="subtitle1" color="secondary">
              {parse(popupData.quote)}
            </Typography>
            <Typography variant="body1" color="secondary">
              {parse(popupData.quoteCaption)}
            </Typography>
          </div>
        </div>
        <div className={classes.titleBlock}>
          <div></div>
          <div className={classes.titleText}>
            <Typography variant="h5" className={classes.title}>
              {parse(popupData.author)}
            </Typography>
            <TypoFramed frame={color} variant="body2" className={classes.role} space={false}>
              {parse(popupData.role)}
            </TypoFramed>
          </div>
        </div>
        <div className={classes.desc}>
          <Typography variant="body1" className={classes.descLeft}>
            {parse(popupData.desc1)}
          </Typography>
          <div className={classes.descRight}>
            <Typography variant="body1">{parse(popupData.desc2)}</Typography>
          </div>
        </div>
      </PopupPage>
    );
  }
}

PopupCEO.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  color: PropTypes.string,
  popupData: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PopupCEO.defaultProps = {
  color: 'black',
};

export default withStyles(styles)(PopupCEO);
