import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PopupPage from '../PopupPage';

import { ReactComponent as CafeMap } from '../../res/cafe-croissant-map.svg';
import parse from '../../utils/parse';
import styles from './styles';

class PopupCafe extends React.Component {
  render() {
    const { classes, open, popupData, onClose } = this.props;
    return (
      <PopupPage key="africa" open={open} onClose={onClose} closeIconColor="pink" className={classes.container}>
        <Typography
          sx={{ mt: '32px', mb: '32px' }}
          key="title"
          variant="h5"
          align="center"
          className={classes.mainTitle}
        >
          <b>{parse(popupData.title)}</b>
        </Typography>
        <CafeMap className={classes.image} />
      </PopupPage>
    );
  }
}

PopupCafe.propTypes = {
  classes: PropTypes.object.isRequired,
  popupData: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(PopupCafe);
