import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B08Aout extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b08aout;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
    this.renderTextSection = this.renderTextSection.bind(this);
  }

  renderTextSection(options = {}, key = -1) {
    const { classes } = this.props;
    const {
      icon,
      title,
      content,
      color,
      image1,
      rotImage1,
      urlImage1,
      image2,
      rotImage2,
      urlImage2,
      imagesCrossed = false,
    } = options;
    const typoClass = color ? classes.color : null;
    return (
      <Stack key={key} sx={{ width: { sm: '100%', md: options.width || '50%' } }} direction="column" spacing={2}>
        {icon && <img className={classes.icon} src={`${this.imgBase}${icon}`} alt="" />}
        {title && (
          <Typography key="title" className={typoClass}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
        <Stack direction="row" width spacing={3} className={imagesCrossed ? classes.imagesCrossed : ''}>
          {image1 && (
            <a href={urlImage1} target="_blank">
              <img className={`${classes.image} ${classes[rotImage1]}`} src={`${this.imgBase}${image1}`} alt="" />
            </a>
          )}
          {image2 && (
            <a href={urlImage2} target="_blank">
              <img className={`${classes.image} ${classes[rotImage2]}`} src={`${this.imgBase}${image2}`} alt="" />
            </a>
          )}
        </Stack>
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column" spacing={3}>
          <Typography className={classes.color} variant="h4" align="center">
            {parse(this.dataBlock.title)}
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pr: '16px', pl: '16px', pb: '58px' }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextSection(
              {
                content: this.dataBlock.parutionContent,
                title: this.dataBlock.parutionTitle,
                color: true,
                image1: this.dataBlock.parutionImage1,
                rotImage1: 'left',
                urlImage1: this.dataBlock.parutionUrlImage1,
                image2: this.dataBlock.parutionImage2,
                rotImage2: 'right',
                urlImage2: this.dataBlock.parutionUrlImage2,
              },
              'parution',
            )}
            {this.renderTextSection(
              {
                content: this.dataBlock.occasionContent,
                title: this.dataBlock.occasionTitle,
                color: true,
                image1: this.dataBlock.occasionImage1,
                rotImage1: 'right',
                urlImage1: this.dataBlock.occasionUrlImage1,
                image2: this.dataBlock.occasionImage2,
                rotImage2: 'left',
                urlImage2: this.dataBlock.occasionUrlImage2,
                imagesCrossed: true,
              },
              'occasion',
            )}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Box className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack sx={{ p: '40px 16px' }} direction="column" spacing={2}>
            <Typography className={classes.white} variant="h6">
              {parse(this.dataBlock.forumTitle)}
            </Typography>
            {this.dataBlock.forumSubTitle.map((c, idx) => (
              <Typography key={idx} variant="body1">
                {parse(c)}
              </Typography>
            ))}
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {this.renderTextSection(
                {
                  content: this.dataBlock.leftContent,
                },
                'left',
              )}
              {this.renderTextSection(
                {
                  content: this.dataBlock.rightContent,
                },
                'right',
              )}
              <img className={classes.illustration} src={`${this.imgBase}${this.dataBlock.bottomImage}`} alt="" />
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B08Aout.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B08Aout);
