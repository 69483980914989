function loop(theme, res, list, type) {
  const suffixes = {
    main: '',
    light: 'light',
    ultralight: 'ultralight',
    dark: 'dark',
    ultradark: 'ultradark',
    medium: 'medium',
    bg: 'bg',
    apple: 'apple',
  };
  const suffixe = suffixes[type];

  list.forEach(key => {
    res[`TypoFramedChild-${key}${suffixe}`] = {
      color: theme.palette[key].contrastText,
      backgroundColor: theme.palette[key][type],
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'blue', 'green', 'ocher', 'orange', 'pink', 'purple'];
  const light = ['grey', 'blue', 'green', 'ocher', 'orange', 'pink', 'purple'];
  const ultralight = ['blue', 'green', 'ocher', 'pink'];
  const dark = ['blue', 'green', 'ocher', 'pink', 'purple'];
  const ultradark = ['blue', 'green', 'purple'];
  const medium = ['blue'];
  const bg = ['blue'];
  const apple = ['green'];

  const res = {
    TypoFramedChild: {
      display: 'inline-block',
      marginBottom: '0.15em',
      padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)}`,
      textTransform: 'initial',
      fontWeight: 'initial',
      lineHeight: 0.8,
      '&.no-space': {
        marginBottom: '0',
      },
      '&.no-lateral-space': {
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');
  loop(theme, res, ultralight, 'ultralight');
  loop(theme, res, ultradark, 'ultradark');
  loop(theme, res, medium, 'medium');
  loop(theme, res, bg, 'bg');
  loop(theme, res, apple, 'apple');

  return res;
};
