import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Hidden from '@mui/material/Hidden';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Burger from '@mui/icons-material/Menu';
import PdfLoader from '../../utils/PdfLoader';
import { ReactComponent as Logo } from '../../res/logo.svg';
import { ReactComponent as DownloadIcon } from '../../res/download-icon.svg';

import data from '../../utils/data';

import styles from './styles';

class Header extends React.Component {
  render() {
    const { classes, toggleOverlay, onPopup } = this.props;
    const { common } = data;
    return (
      <AppBar elevation={0} position="relative" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Logo className={classes.logo} />
          <Box sx={{ flex: 1 }}></Box>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, md: 2 }}>
            <Hidden mdDown>
              <Button
                className={classes.buttonText}
                variant="text"
                color="secondary"
                onClick={() => onPopup('chiffres', true)}
              >
                {common.headerChiffresCle}
              </Button>
              <Button
                className={classes.buttonText}
                variant="text"
                color="secondary"
                onClick={() => onPopup('social', true)}
              >
                {common.headerReseaux}
              </Button>
              <Button
                onClick={PdfLoader(common.pdf)}
                sx={{ fontSize: '1em', backgroundColor: theme => theme.palette.blue.medium }}
                variant="contained"
                color="primary"
                className={classes.buttonPDF}
                endIcon={<DownloadIcon />}
              >
                {common.headerDownload}
              </Button>
            </Hidden>
            <Hidden lgUp>
              <Burger className={classes.burger} color="secondary" onClick={() => toggleOverlay(true)} />
            </Hidden>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleOverlay: PropTypes.func,
  onPopup: PropTypes.func,
};

export default withStyles(styles)(Header);
