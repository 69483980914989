export default theme => ({
  container: {
    position: 'fixed',
    zIndex: 1000,
    width: theme.spacing(7),
    left: 0,
    top: 'calc(50% + 12px)',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'visible',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(4),
    },
  },
  overlay: {
    zIndex: '90 !important',
  },
  entry: {
    position: 'relative',
    zIndex: 1,
    height: 40,
    '&:hover::before': {
      backgroundColor: theme.palette.blue.ultradark,
    },
    '&::before': {
      content: '""',
      position: 'relative',
      display: 'block',
      backgroundColor: 'transparent',
      width: 16,
      height: 16,
      top: '0%',
      left: '0%',
      border: `solid 2px ${theme.palette.blue.ultradark}`,
      boxSizing: 'border-box',
      borderRadius: '50%',
      transition: 'background-color 0.3s ease-in-out',
      [theme.breakpoints.down('md')]: {
        width: 8,
        height: 8,
      },
    },
    '&:not(:last-child):after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      backgroundColor: theme.palette.blue.ultradark,
      width: 2,
      height: 25,
      top: 15,
      left: 8,
      transform: 'translateX(-50%)',
      [theme.breakpoints.down('md')]: {
        top: 8,
        left: 4,
        height: 32,
      },
    },
  },
  active: {
    '&::before': {
      backgroundColor: theme.palette.blue.ultradark,
    },
  },
  label: {
    position: 'absolute',
    height: 20,
    top: -2,
    left: 25,
    backgroundColor: 'rgba(18, 47, 121, 0.5)',
    borderRadius: 3,
    '&::after': {
      width: '100%',
      height: '50px',
      content: '""',
      position: 'relative',
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.blue.dark,
      top: -6,
      left: 15,
    },
  },
  link: {
    position: 'relative',
    textTransform: 'uppercase',
    color: theme.palette.white.main,
    padding: theme.spacing(0.25, 1),
    top: 'calc(50% + 1px)',
    transform: 'translateY(-50%)',
    left: 0,
    width: 'max-content',
  },
});
