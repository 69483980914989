import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, Hidden, Typography } from '@mui/material';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B04Avril extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b04avril;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderText(options = {}, index = -1) {
    const { classes } = this.props;
    const { icon, title, myClass, content } = options;

    return (
      <Stack
        key={index}
        sx={{ width: { sm: '100%', md: options.width || '50%' } }}
        direction="column"
        spacing={2}
        className={classes[myClass]}
      >
        {icon && <img className={classes.icon} src={`${this.imgBase}${icon}`} alt="" />}
        {title && (
          <Typography key="title" className={classes.color}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderTextSections(options = {}) {
    const { classes } = this.props;

    return (
      <Container maxWidth="md" className={classes.blockContainer} sx={{ pl: '30px', pr: '30px' }}>
        <Stack direction="column" spacing={3}>
          {options.title && (
            <Typography className={classes.color} variant="h4" align="center">
              {parse(options.title)}
            </Typography>
          )}
          {options.subtitle && (
            <Typography>
              <b>{parse(options.subtitle)}</b>
            </Typography>
          )}
          {options.content && Array.isArray(options.content) && (
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {options.content.map((c, idx) => this.renderText(c, idx))}
            </Stack>
          )}
        </Stack>
      </Container>
    );
  }

  renderSimpleTextSection(content, customWidth = '100%') {
    const { classes } = this.props;
    return (
      <Stack
        sx={{ width: { xs: '100%', md: customWidth }, pl: '24px', pr: '24px', pb: '24px' }}
        direction="column"
        spacing={2}
      >
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1" className={classes.text}>
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderSecondSection() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={{ xs: 2, md: 8 }}
          justifyContent="space-between"
        >
          {this.renderSimpleTextSection(this.dataBlock.leftText)}
          {this.renderSimpleTextSection(this.dataBlock.rightText)}
        </Stack>
      </Container>
    );
  }

  renderThirdSection() {
    const { classes } = this.props;
    return (
      <Box sx={{ width: '100%', pl: '24px', pr: '24px', pb: '50px' }} className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack direction="column" alignItems="center" spacing={4}>
            <img className={classes.boxedImg1} src={`${this.imgBase}${this.dataBlock.boxedImg1}`} alt="" />
            <Stack
              direction={{
                xs: 'column-reverse',
                md: 'row',
              }}
              spacing={4}
              justifyContent="space-between"
            >
              <Stack sx={{ width: { xs: '100%', md: '60%' } }} direction="column" spacing={2}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {parse(this.dataBlock.boxedTitle)}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                  {parse(this.dataBlock.boxedSubtitle1)}
                </Typography>
                <Typography variant="body1">{parse(this.dataBlock.boxedText1)}</Typography>
                <img className={classes.boxedImg2} src={`${this.imgBase}${this.dataBlock.boxedImg2}`} alt="" />
                <Stack sx={{ width: { xs: '100%', md: '60%' } }} direction="column" spacing={2}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {parse(this.dataBlock.boxedSubtitle2)}
                  </Typography>
                  <Typography variant="body1">{parse(this.dataBlock.boxedText2)}</Typography>
                  <Hidden lgUp>
                    <img className={classes.boxedImg3} src={`${this.imgBase}${this.dataBlock.boxedImg3}`} alt="" />
                  </Hidden>
                  <a
                    href="https://www.francealumni.fr/fr/news/rencontres-france-alumni-a-ramallah-7500"
                    target="_blank"
                  >
                    <Button variant="contained" disableElevation className={classes.btn}>
                      {parse(this.dataBlock.boxedBtn)}
                    </Button>
                  </a>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: { xs: '100%', md: '40%' },
                  border: 'solid 1px white',
                  position: 'relative',
                }}
                direction="column"
                spacing={2}
                alignSelf="flex-start"
                className={classes.numbers}
              >
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    position: 'relative',
                    backgroundColor: '#5BB4E0',
                    top: '-11px',
                    width: 'fit-content',
                    m: 'auto',
                    pl: '10px',
                    pr: '10px',
                  }}
                >
                  {parse(this.dataBlock.numbersTitle)}
                </Typography>
                <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
                  {parse(this.dataBlock.number1)}
                </Typography>
                <Typography variant="body1" align="center" sx={{ mt: '0 !important', mb: '32px !important' }}>
                  {parse(this.dataBlock.number1Text)}
                </Typography>
                <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
                  {parse(this.dataBlock.number2)}
                </Typography>
                <Typography variant="body1" align="center" sx={{ mt: '0 !important', mb: '32px !important' }}>
                  {parse(this.dataBlock.number2Text)}
                </Typography>
                <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
                  {parse(this.dataBlock.number3)}
                </Typography>
                <Typography variant="body1" align="center" sx={{ mt: '0 !important', mb: '32px !important' }}>
                  {parse(this.dataBlock.number3Text)}
                </Typography>
                <Hidden mdDown>
                  <img className={classes.boxedImg3} src={`${this.imgBase}${this.dataBlock.boxedImg3}`} alt="" />
                </Hidden>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTextSections({
          title: this.dataBlock.mainTitle,
          subtitle: this.dataBlock.subtitle,
          content: [
            {
              icon: this.dataBlock.doctorateIcon,
              title: this.dataBlock.doctorateTitle,
              content: this.dataBlock.doctorateText,
            },
            {
              icon: this.dataBlock.commissionIcon,
              title: this.dataBlock.commissionTitle,
              content: this.dataBlock.commissionText,
            },
            {
              title: this.dataBlock.asideTitle,
              content: this.dataBlock.asideText,
              myClass: this.dataBlock.asideClass,
            },
          ],
        })}
        <Container maxWidth="md" sx={{ p: { xs: '30px', md: '0' } }}>
          <img className={classes.illustration} src={`${this.imgBase}${this.dataBlock.illustration}`} alt="" />
        </Container>
        {this.renderSecondSection()}
        {this.renderThirdSection()}
      </Box>
    );
  }
}

B04Avril.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B04Avril);
