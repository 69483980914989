import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, DialogContent, Fab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

class PopupPage extends React.Component {
  render() {
    const { classes, open, onClose, closeIconColor, children, DialogContentProps } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="body"
        open={open || false}
        onClose={onClose}
        PaperProps={{ square: true, className: classes.dialogPaper }}
      >
        <Fab color="primary" onClick={onClose} className={`${classes.closeIcon} ${classes[closeIconColor]}`}>
          <CloseIcon fontSize="large" />
        </Fab>
        <DialogContent className={classes.dialogContent} {...DialogContentProps}>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

PopupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  closeIconColor: PropTypes.oneOf(['green', 'pink', 'ocher', 'blue']),
  DialogContentProps: PropTypes.object,
};

PopupPage.defaultProps = {
  closeIconColor: 'green',
};

export default withStyles(styles)(PopupPage);
