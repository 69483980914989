import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Hidden from '@mui/material/Hidden';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as Logo } from '../../res/logo.svg';

import data from '../../utils/data';

import styles from './styles';

class Footer extends React.Component {
  render() {
    const { classes, onPopup } = this.props;
    const { common } = data;
    return (
      <Hidden mdDown>
        <AppBar position="relative">
          <Toolbar className={classes.toolbar}>
            <Logo />
            <Box sx={{ flex: 1 }}></Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              <a href={common.footerReturnLink}>
                <Button className={classes.buttonText} variant="text" color="secondary">
                  {common.footerReturn}
                </Button>
              </a>
              <Button
                className={classes.buttonText}
                variant="text"
                color="secondary"
                onClick={() => onPopup('credits', true)}
              >
                {common.footerCredit}
              </Button>
              <Typography>Design LUCIOLE 2022</Typography>
            </Stack>
          </Toolbar>
        </AppBar>
      </Hidden>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  onPopup: PropTypes.func,
};

export default withStyles(styles)(Footer);
