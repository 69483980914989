export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
  },
  promotion: {
    marginBottom: theme.spacing(8),
  },
  programsTitle: {
    paddingBottom: theme.spacing(2),
  },
  icon: {
    alignSelf: 'flex-start',
    height: 80,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: 55,
      height: 55,
    },
  },
  smallIcon: {
    alignSelf: 'flex-start',
    height: 50,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      height: 40,
    },
  },
  darkpink: {
    color: theme.palette.pink.dark,
  },
  pink: {
    backgroundColor: theme.palette.pink.main,
    color: theme.palette.pink.contrastText,
  },
  programs: {
    position: 'relative',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginBottom: theme.spacing(42),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  image: {
    position: 'absolute',
    left: '50%',
    bottom: -250,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      position: 'initial',
      left: 0,
      bottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  caption: {
    color: theme.palette.black.main,
  },
  coffee: {
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginBottom: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 64px) !important',
      margin: `${theme.spacing(8, 4)}`,
      marginBottom: 0,
    },
  },
  coffeeImg: {
    width: 220,
  },
  btn: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.pink.main} !important`,
  },
});
