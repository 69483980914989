import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PopupCafe from '../../popups/PopupCafe';

import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';

class B03Mars extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b03mars;
    this.dataPopup = data.popups.cafe;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderTextSection(content, idx, title = null, src = null, customWidth = '33%', isSmall = false, illusSrc = null) {
    const { classes } = this.props;
    return (
      <Stack
        key={idx}
        sx={{
          width: {
            sm: '100%',
            md: customWidth,
          },
          maxWidth: {
            sm: '100%',
            md: '400px',
          },
          pl: '16px',
          pr: '16px',
        }}
        direction="column"
        spacing={2}
      >
        {src && <img className={isSmall ? classes.smallIcon : classes.icon} src={`${this.imgBase}${src}`} alt="" />}
        {title && (
          <Typography key="title" variant="body1" className={classes.darkpink}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {content && (
          <Typography key="content" variant="body1" className={classes.text}>
            {parse(content)}
          </Typography>
        )}
        {illusSrc && <img className={classes.illus} src={`${this.imgBase}${illusSrc}`} alt="" />}
      </Stack>
    );
  }

  renderPromotion() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg" className={classes.promotion}>
        <Stack direction="column" spacing={3}>
          <Typography sx={{ p: '24px' }} className={classes.darkpink} variant="h5" align="center">
            {parse(this.dataBlock.promotionTitle)}
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.dataBlock.promotionContent.map((item, idx) =>
              this.renderTextSection(item.text, idx, item.title, item.icon, '33%', false, item.illus),
            )}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderPrograms() {
    const { classes } = this.props;
    return (
      <Box className={classes.pink}>
        <Container maxWidth="lg" className={classes.programs}>
          <Stack direction="column" spacing={3}>
            <Typography className={classes.programsTitle} variant="h6" align="center">
              {this.dataBlock.programsTitle}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="center"
              spacing={8}
            >
              {this.dataBlock.programsContent.map((item, idx) =>
                this.renderTextSection(item.text, idx, null, item.icon, '50%', true),
              )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} className={classes.image}>
            <img src={`${this.imgBase}${this.dataBlock.programsImg}`} alt="" />
          </Stack>
        </Container>
      </Box>
    );
  }

  renderCoffee() {
    const { classes, onPopup } = this.props;
    return (
      <Container maxWidth="md" className={`${classes.pink} ${classes.coffee}`}>
        <Stack direction="column" spacing={3} m="auto" maxWidth="500px" alignItems="center">
          <img src={`${this.imgBase}${this.dataBlock.coffeeImg}`} alt="" className={classes.coffeeImg} />
          <Typography variant="body1" align="center">
            {parse(this.dataBlock.coffeeText)}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            className={classes.btn}
            onClick={() => onPopup(this.dataBlock.popupTrigger, true)}
          >
            {parse(this.dataBlock.coffeeBtn)}
          </Button>
        </Stack>
      </Container>
    );
  }

  render() {
    const { classes, onPopup, popupsState, baseUrl } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderPromotion()}
        {this.renderPrograms()}
        {this.renderCoffee()}
        <PopupCafe
          popupData={this.dataPopup}
          open={!!popupsState[this.dataBlock.popupTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupTrigger, false)}
        />
      </Box>
    );
  }
}

B03Mars.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B03Mars);
