export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),

    '& .dash': {
      color: theme.palette.ocher.light,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
    },
  },
  icon: {
    width: 76,
    height: 80,
    stroke: theme.palette.ocher.main,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: 55,
      height: 55,
    },
  },
  illustration: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: 'auto !important',
    },
  },
  color: {
    color: theme.palette.ocher.light,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  colorBackground: {
    backgroundColor: theme.palette.ocher.light,
    color: theme.palette.ocher.contrastText,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  statContainer: {
    width: 'calc(100% - 24px)',
    border: '1px solid',
    padding: '40px 70px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '40px 40px',
    },
  },
  statTitle: {
    width: '100%',
    position: 'absolute',
    top: -10,
    left: 0,
    '& > b': {
      backgroundColor: theme.palette.ocher.light,
      padding: '0 20px',
    },
  },
  statContent: {
    width: '25%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& h5': {
        fontSize: '1.75rem',
      },
      '& p': {
        marginTop: '0 !important',
      },
    },
  },
  imageContainer: {
    width: '50%',
  },
  labelImage: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  labelPatch: {
    width: 192,
    height: 192,
    [theme.breakpoints.down('md')]: {
      width: 120,
      height: 120,
    },
  },
  labelContent: {
    color: theme.palette.blue.dark,
  },
  labelBtn: {
    display: 'block',
    margin: 'auto',
    paddingTop: theme.spacing(4),
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
});
