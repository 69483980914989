export default theme => ({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  mainTitle: {
    color: theme.palette.pink.main,
  },
  image: {
    maxHeight: 620,
    width: '100%',
  },
});
