import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography, Slide } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';
import PdfLoader from '../../utils/PdfLoader';
import { ReactComponent as DownloadIcon } from '../../res/download-icon.svg';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.dataMenu = data.menu;
  }

  render() {
    const { classes, inViewStates, onMonthClick, onPopup, toggleOverlay, overlayState } = this.props;
    const { common } = data;

    return (
      <Slide direction="up" in={overlayState} mountOnEnter unmountOnExit>
        <div className={classes.container}>
          <Close className={classes.close} color="secondary" onClick={() => toggleOverlay(false)} />
          <div className={classes.months}>
            {this.dataMenu.map((item, idx) => (
              <div
                key={idx}
                className={`${classes.entry} ${inViewStates[item.anchor] ? classes.active : ''}`}
                onClick={() => onMonthClick(item.anchor)}
              >
                <div className={classes.label}>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className={classes.link}>
                    {parse(item.title)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
          <Stack direction="column" alignItems="center" spacing={1} className={classes.buttons}>
            <Button
              className={classes.buttonText}
              variant="text"
              color="secondary"
              onClick={() => onPopup('chiffres', true)}
            >
              {common.headerChiffresCle}
            </Button>
            <Button
              className={classes.buttonText}
              variant="text"
              color="secondary"
              onClick={() => onPopup('social', true)}
            >
              {common.headerReseaux}
            </Button>
            <Button
              onClick={PdfLoader(common.pdf)}
              sx={{ fontSize: '1em', backgroundColor: theme => theme.palette.green.medium }}
              variant="contained"
              color="primary"
              className={classes.buttonPDF}
              endIcon={<DownloadIcon />}
            >
              {common.headerDownload}
            </Button>
          </Stack>
        </div>
      </Slide>
    );
  }
}

MobileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  inViewStates: PropTypes.object,
  onMonthClick: PropTypes.func,
  toggleOverlay: PropTypes.func,
  overlayState: PropTypes.bool,
  onPopup: PropTypes.func,
};

export default withStyles(styles)(MobileMenu);
