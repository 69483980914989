export default theme => ({
  dialogPaper: {
    overflow: 'initial !important',
    backgroundColor: theme.palette.primary.main,
    height: '60vh',
  },
  dialogContent: {
    padding: 0,
    width: '100%',
    height: '100%',
    minHeight: 250,
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
      '& svg': {
        height: 25,
        width: 25,
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: 32,
      width: 32,
      minHeight: 32,
      right: theme.spacing(4),
      '& svg': {
        height: 20,
        width: 20,
      },
    },
  },
  player: {
    minHeight: 250,
  },
  playIcon: {
    width: 100,
    height: 100,
    '& .icon-fill': {
      fill: theme.palette.common.white,
    },
    '& .icon-stroke': {
      stroke: theme.palette.common.white,
    },
  },
});
