export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
    position: 'relative',
    '& .dash': {
      color: theme.palette.blue.light,
    },
    '& .color': {
      color: theme.palette.blue.dark,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
  tableauBluePart: {
    position: 'relative',
    backgroundColor: theme.palette.blue.ultralight,
    color: theme.palette.blue.contrastText,
    padding: theme.spacing(6),
    '& span': {
      margin: '5px 0px',
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  seminaireImageContainer: {
    height: 120,
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      height: 'initial',
      fontSize: 0,
    },
  },
  seminaireRedPart: {
    backgroundColor: theme.palette.ocher.main,
    color: theme.palette.ocher.contrastText,
    padding: theme.spacing(6),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
    '& span': {
      margin: '10px 0px',
      display: 'inline-block',
    },
  },
  soutienCocacImg: {
    width: '48% !important',
    position: 'absolute',
    top: '20%',
    left: 0,
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      position: 'initial',
    },
  },
  diplomatieRedPart: {
    backgroundColor: theme.palette.ocher.main,
    color: theme.palette.ocher.contrastText,
    padding: theme.spacing(6),
    marginTop: theme.spacing(6),
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      marginTop: 0,
    },
    '& span': {
      margin: '10px 0px',
      display: 'inline-block',
    },
  },
  videoBlueBlock: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  conseilBluePart: {
    backgroundColor: theme.palette.blue.ultralight,
    color: theme.palette.blue.contrastText,
    padding: theme.spacing(6),
    paddingRight: '33%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  imgRedBlock: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  signatureRedPart: {
    backgroundColor: theme.palette.ocher.main,
    color: theme.palette.ocher.contrastText,
    padding: theme.spacing(6),
    marginTop: '30%',
    marginBottom: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      margin: 'initial',
    },
  },
  image: {
    width: '100%',
    objectFit: 'contain',
  },
  illustration: {
    position: 'absolute',
    top: 20,
    left: 'calc(100% - 20px)',
    maxWidth: 400,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    alignSelf: 'flex-start',
    height: 50,
    width: 50,
    objectFit: 'contain',
  },
  color: {
    color: theme.palette.blue.ultralight,
  },
  colorBackground: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
  },
  btn: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.ocher.main} !important`,
    margin: 'auto',
    '& a': {
      color: theme.palette.ocher.main,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      margin: 'auto !important',
      display: 'block !important',
      marginTop: `${theme.spacing(2)} !important`,
    },
  },
  btnBlue: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.blue.ultralight} !important`,
    margin: 'auto',
    '& a': {
      color: theme.palette.blue.ultralight,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      margin: 'auto !important',
      display: 'block !important',
      marginTop: `${theme.spacing(3)} !important`,
      marginBottom: `${theme.spacing(2)} !important`,
    },
  },
  play: {
    width: 171,
    height: 171,
    position: 'absolute',
    pointerEvents: 'none',
    '& path': {
      stroke: theme.palette.white.main,
      transition: 'stroke 0.3s ease-in-out',
    },
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
    },
  },
  videoContainer: {
    width: '65%',
    height: 406,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '20%',
    right: 0,
    marginLeft: 'auto',
    backgroundColor: 'grey',
    '&:hover path': {
      stroke: `${theme.palette.blue.dark} !important`,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 220,
      position: 'initial',
      left: 0,
      top: 0,
      marginLeft: 0,
    },
  },
  videoImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
});
