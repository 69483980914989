import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Stack, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PopupPage from '../PopupPage';

import styles from './styles';

class PopupEuropa extends React.Component {
  renderTextSection(content, index, columnWidth = '50%') {
    const { classes } = this.props;
    return (
      <Stack key={index} sx={{ width: { sm: '100%', md: columnWidth } }} direction="column" spacing={2}>
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <React.Fragment key={idx}>
              {c.heading && (
                <Typography
                  key="heading"
                  variant="body1"
                  sx={{ fontWeight: 'bold', mt: '0 !important', mb: '16px !important' }}
                  className={classes.heading}
                >
                  {parse(c.heading)}
                </Typography>
              )}
              {c.title && (
                <Typography
                  key="title"
                  variant="body1"
                  sx={{ mt: '0 !important', mb: '16px !important' }}
                  className={classes.title}
                >
                  {parse(c.title)}
                </Typography>
              )}
              {c.text && (
                <Typography
                  key="text"
                  variant="body1"
                  sx={{ mt: '0 !important', mb: '16px !important' }}
                  className={classes.text}
                >
                  {parse(c.text)}
                </Typography>
              )}
            </React.Fragment>
          ))}
      </Stack>
    );
  }

  render() {
    const { popupData, classes, open, onClose, baseUrl } = this.props;
    const has2Columns = popupData.column2 !== undefined;
    const imgSrc1 = `${baseUrl}/popups/${parse(popupData.imgSrc1)}`;
    const imgSrc2 = `${baseUrl}/popups/${parse(popupData.imgSrc2)}`;
    return (
      <PopupPage key="anmo" open={open} onClose={onClose} closeIconColor="green" className={classes.container}>
        <Stack key="stack01" direction="column" className={classes.content}>
          <Typography key="title" variant="h5" align="center" className={classes.mainTitle}>
            <b>{parse(popupData.title)}</b>
          </Typography>
          <Stack
            key="text"
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
          >
            {has2Columns
              ? this.renderTextSection(popupData.column1, 'text01')
              : this.renderTextSection(popupData.column1, 'text01', '100%')}
            {has2Columns ? this.renderTextSection(popupData.column2, 'text02') : null}
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ marginTop: 2 }}
            spacing={2}
          >
            <Box sx={{ width: { sm: '100%', md: '50%' }, m: 'auto' }}>
              <img key="img1" src={imgSrc1} className={classes.image} alt="" />
            </Box>
            <Box sx={{ width: { sm: '100%', md: '50%' } }}>
              <img key="img2" src={imgSrc2} className={classes.image} alt="" />
            </Box>
          </Stack>
        </Stack>
      </PopupPage>
    );
  }
}

PopupEuropa.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  popupData: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(PopupEuropa);
