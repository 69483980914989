export default theme => ({
  appbar: {
    position: 'relative',
    zIndex: 101,
  },
  toolbar: {
    height: 91,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  buttonText: {
    fontSize: '1em',
    textDecoration: 'underline !important',
    textTransform: 'initial !important',
    fontWeight: 'lighter !important',
  },
  buttonPDF: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6rem !important',
      width: '125px !important',
      '& *': {
        display: 'none !important',
      },
    },
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      width: '100px !important',
    },
  },
});
