import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, Typography } from '@mui/material';

import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';

class DesktopMenu extends React.Component {
  constructor(props) {
    super(props);
    this.dataMenu = data.menu;
  }

  render() {
    const { classes, inViewStates, onMonthClick, toggleOverlay, overlayState } = this.props;
    return (
      <>
        <Dialog open={overlayState} className={classes.overlay}></Dialog>
        <div
          className={classes.container}
          onMouseEnter={() => toggleOverlay(true)}
          onMouseLeave={() => toggleOverlay(false)}
        >
          {this.dataMenu.map((item, idx) => (
            <div
              key={idx}
              className={`${classes.entry} ${inViewStates[item.anchor] ? classes.active : ''}`}
              onClick={() => onMonthClick(item.anchor)}
            >
              {overlayState && (
                <div className={classes.label}>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem' }} className={classes.link}>
                    {parse(item.title)}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
}

DesktopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  inViewStates: PropTypes.object,
  onMonthClick: PropTypes.func,
  toggleOverlay: PropTypes.func,
  overlayState: PropTypes.bool,
};

export default withStyles(styles)(DesktopMenu);
