import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import DeviceInfos from '../../utils/DeviceInfos';

// import { ReactComponent as QuoteIcon } from '../../res/quote-icon.svg';

import Anchor from '../Anchor';
import parse from '../../utils/parse';
import styles from './styles';

class TitleMonth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inView: false,
      animationBackgroundActivated: [],
    };

    this.startAnimation = this.startAnimation.bind(this);
    this.animateBackground = this.animateBackground.bind(this);
  }

  startAnimation(viewParams) {
    if (viewParams && !this.state.inView) {
      this.setState({ inView: true }, this.animateBackground);
    }
  }

  animateBackground(idx = -1) {
    if (this.state.inView) {
      const { backgroundAnimationParams } = this.props;
      const { order, timing } = backgroundAnimationParams;
      const animationImageIndex = idx + 1;
      if (animationImageIndex < order.length) {
        this.setState(
          prev => {
            const animationBackgroundActivated = [...prev.animationBackgroundActivated];
            animationBackgroundActivated.push(order[animationImageIndex]);
            return { animationBackgroundActivated };
          },
          () => setTimeout(() => this.animateBackground(animationImageIndex), timing),
        );
      }
    }
  }

  renderStandardTitle() {
    const { classes, dataComponent } = this.props;
    return <span className={classes.textBottom}>{parse(dataComponent.textBottom)}</span>;
  }

  renderBigTitle() {
    const { classes, dataComponent } = this.props;
    return (
      <Typography variant="h1" className={classes.textBottomBig}>
        {parse(dataComponent.textBottom)}
      </Typography>
    );
  }

  renderBackgroundAnimation() {
    const { classes, baseUrl, backgroundAnimationParams } = this.props;
    const { animationBackgroundActivated } = this.state;
    const { images, order } = backgroundAnimationParams;
    return (
      <InView onChange={this.startAnimation} className={classes.backgroundAnimationWrapper}>
        <div className={classes.backgroundAnimationContainer}>
          {order.map((index, idx) => (
            <div
              key={index}
              data-index={idx}
              className={`${classes.backgroundAnimationContent} ${
                animationBackgroundActivated.includes(idx) ? 'active' : 'inactive'
              }`}
            >
              <img src={`${baseUrl}/${images[index]}`} alt="" />
            </div>
          ))}
        </div>
      </InView>
    );
  }

  render() {
    const { classes, anchor, isBig, dataComponent, color, colorDesc, baseUrl, useMargin, backgroundAnimationParams } =
      this.props;
    const imgBase = `${baseUrl}/components/`;
    return (
      <Container sx={{ marginBottom: useMargin ? 0 : null }} maxWidth="lg" className={classes.blockContainer}>
        {anchor && <Anchor id={`${anchor}`} />}
        <div className={classes.bg}>
          {backgroundAnimationParams ? (
            this.renderBackgroundAnimation()
          ) : (
            <img src={`${imgBase}${dataComponent.bg}`} alt="" />
          )}
          <Typography variant="h1" className={classes.title}>
            <span className={isBig && DeviceInfos.device.type !== 'tablet' ? classes.textTopBig : classes.textTopBig}>
              {parse(dataComponent.textTop)}
            </span>
            {isBig && DeviceInfos.device.type !== 'tablet' ? null : this.renderStandardTitle()}
          </Typography>
        </div>
        <div className={`${classes[color]} ${classes.coloredSquare}`}>
          {isBig && DeviceInfos.device.type !== 'tablet' ? this.renderBigTitle() : null}
        </div>
        <div className={`${classes[colorDesc]} ${classes.desc}`}>
          {/* <QuoteIcon className={classes.quoteIcon} /> */}
          <Typography variant="subtitle1" className={classes.descText}>
            {parse(dataComponent.desc)}
          </Typography>
        </div>
      </Container>
    );
  }
}

TitleMonth.defaultProps = {
  backgroundAnimationParams: null,
};

TitleMonth.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  colorDesc: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  isBig: PropTypes.bool,
  useMargin: PropTypes.bool,
  backgroundAnimationParams: PropTypes.object,
};

export default withStyles(styles)(TitleMonth);
