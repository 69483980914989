import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B02Fevrier extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b02fevrier;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
    this.renderTextSection = this.renderTextSection.bind(this);
  }

  renderTextSection(content, title = null, src = null, customWidth = '50%', illusSrc = null) {
    const { classes } = this.props;
    return (
      <Stack
        sx={{
          width: {
            xs: '100%',
            md: customWidth,
          },
          p: '12px',
          pb: '24px',
        }}
        direction="column"
        spacing={2}
      >
        {src && <img className={classes.icon} src={`${this.imgBase}${src}`} alt="" />}
        {title && (
          <Typography key="title" className={classes.purple}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
        {illusSrc && <img className={classes.illus} src={`${this.imgBase}${illusSrc}`} alt="" />}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column" spacing={3}>
          <Typography className={classes.purple} variant="h4" align="center">
            {parse(this.dataBlock.topTitle)}
          </Typography>
          <Typography sx={{ pl: '12px', pr: '12px' }}>
            <b>{parse(this.dataBlock.topSubtitle)}</b>
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextSection(
              this.dataBlock.stepContent,
              this.dataBlock.stepTitle,
              this.dataBlock.stepIcon,
              '50%',
              this.dataBlock.stepIllus,
            )}
            {this.renderTextSection(this.dataBlock.bilanContent, this.dataBlock.bilanTitle, this.dataBlock.bilanIcon)}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Box className={classes.purpleBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack direction="column" spacing={2}>
            <Typography className={`${classes.white} ${classes.title}`} variant="h6">
              {parse(this.dataBlock.bottomTitle)}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={{ xs: 0, md: 4 }}
            >
              {this.renderTextSection(this.dataBlock.bottomLeftContent, null, null, '65%')}
              {this.renderTextSection(this.dataBlock.bottomRightContent, null, null, '35%')}
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B02Fevrier.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B02Fevrier);
