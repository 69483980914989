export default theme => ({
  toolbar: {
    height: 203,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  buttonText: {
    fontSize: '1em',
    textDecoration: 'underline !important',
    textTransform: 'initial !important',
    fontWeight: 'lighter !important',
  },
});
