export default theme => ({
  dialogPaper: {
    overflow: 'initial !important',
    backgroundColor: theme.palette.white.main,
    color: theme.palette.white.contrastText,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(4),
    },
    '& > *': {
      position: 'relative',
    },
  },
  dialogContent: {
    padding: '0 !important',
  },
  green: {
    backgroundColor: `${theme.palette.green.ultradark} !important`,
  },
  ocher: {
    backgroundColor: `${theme.palette.ocher.main} !important`,
  },
  pink: {
    backgroundColor: `${theme.palette.pink.main} !important`,
  },
  blue: {
    backgroundColor: `${theme.palette.blue.dark} !important`,
  },
  closeIcon: {
    position: 'absolute !important',
    zIndex: 5,
    top: 0,
    right: 0,
    transform: 'translate(50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
      '& svg': {
        height: 25,
        width: 25,
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: 32,
      width: 32,
      minHeight: 32,
      right: theme.spacing(4),
      '& svg': {
        height: 20,
        width: 20,
      },
    },
  },
});
