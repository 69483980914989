export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
    position: 'relative',
    '& .dash': {
      color: theme.palette.green.light,
    },
    '& .color': {
      color: theme.palette.green.dark,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
  title: {
    color: theme.palette.green.dark,
    paddingBottom: theme.spacing(4),
  },
  icon: {
    marginTop: theme.spacing(2),
  },
  image: {
    width: '65%',
    marginLeft: '6%',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '40%',
      maxHeight: 100,
      margin: 'auto',
    },
  },
  illustration: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: 'auto !important',
      paddingTop: theme.spacing(2),
    },
  },
  illustrationBottom: {
    width: '80%',
    display: 'block',
    margin: 'auto !important',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  color: {
    color: theme.palette.green.dark,
  },
  colorBackground: {
    backgroundColor: theme.palette.green.apple,
    color: theme.palette.green.contrastText,
  },
  partners: {
    '& img': {
      height: '30px',
      objectFit: 'contain',
    },
    '& p': {
      marginTop: `${theme.spacing(0.5)} !important`,
    },
  },
  videoContainer: {
    width: '65%',
    height: 406,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '20%',
    right: 0,
    marginLeft: 'auto',
    backgroundColor: 'grey',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 220,
      position: 'initial',
      left: 0,
      top: 0,
      marginLeft: 0,
      marginTop: '0 !important',
    },
    '&:hover path': {
      stroke: `${theme.palette.blue.dark} !important`,
    },
  },
  play: {
    width: 171,
    height: 171,
    position: 'absolute',
    pointerEvents: 'none',
    '& path': {
      stroke: theme.palette.white.main,
      transition: 'stroke 0.3s ease-in-out',
    },
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
    },
  },
  videoImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      maxHeight: 250,
    },
  },
  campusText: {
    backgroundColor: theme.palette.green.dark,
    color: theme.palette.green.contrastText,
    padding: theme.spacing(8),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
});
