import { createTheme } from '@mui/material/styles';
const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

const theme = createTheme({
  url: s => `${BASE_URL}${s}`,
  palette: {
    primary: {
      main: '#11337F',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#000',
    },
    white: {
      main: '#FFF',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      contrastText: '#FFF',
    },
    grey: {
      main: '#E6E6E6',
      light: '#EDEDED',
      contrastText: '#000',
    },
    blue: {
      main: '#083EA0',
      medium: '#4B629D',
      bg: '#E3F3FB',
      light: '#5BB4E0',
      ultralight: '#93C3EB',
      dark: '#11337F',
      ultradark: '#122F79',
      contrastText: '#FFF',
    },
    green: {
      main: '#39BB76',
      apple: '#71B458',
      light: '#4BB58F',
      ultralight: '#69CA95',
      dark: '#186863',
      ultradark: '#006951',
      contrastText: '#FFF',
    },
    ocher: {
      main: '#FF5F49',
      dark: '#E41E13',
      light: '#FC6F49',
      ultralight: '#FD9877',
      contrastText: '#FFF',
    },
    orange: {
      main: '#E86109',
      light: '#FFB942',
      contrastText: '#FFF',
    },
    pink: {
      main: '#FD626E',
      light: '#F0858D',
      ultralight: '#FFA2A6',
      dark: '#E20146',
      contrastText: '#FFF',
    },
    purple: {
      main: '#A95D83',
      light: '#B789C3',
      dark: '#703B9E',
      ultradark: '#933165',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: 'CampusFranceSans, CampusFranceSerif, Roboto, sans-serif',
    h1: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: 1,
      letterSpacing: '-3.68px',
      fontSize: '11.5rem',
      // '@media (min-width:600px) and (min-height:720px)': {
      //   fontSize: '2.6rem',
      // },
      // '@media (min-width:960px) and (min-height:720px)': {
      //   fontSize: '3.0625rem',
      // },
    },
    h2: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.57,
      letterSpacing: 0,
      fontSize: '6.44rem',
    },
    h3: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.57,
      fontSize: '4.44rem',
    },
    h4: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1,
      fontSize: '2.54rem',
      '@media (max-width:960px)': {
        fontSize: '1.31rem',
      },
    },
    h5: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      lineHeight: 1.28,
      letterSpacing: '0em',
      fontSize: '2.19rem',
      '@media (max-width:960px)': {
        fontSize: '1.14rem',
      },
    },
    h6: {
      fontFamily: 'CampusFranceSerif, serif',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '0em',
      fontSize: '1.57rem',
      '@media (max-width:960px)': {
        fontSize: '1rem',
      },
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: 1.375,
      letterSpacing: 0,
      fontSize: '1rem',
      '@media (max-width:960px)': {
        fontSize: '0.8rem',
      },
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: 1.33,
      letterSpacing: 0,
      fontSize: '0.94rem',
    },
    subtitle1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 300,
      lineHeight: 1.32,
      letterSpacing: 0,
      fontSize: '1.57rem',
      '@media (max-width:960px)': {
        fontSize: '1.2rem',
      },
    },
    subtitle2: {
      fontFamily: 'CampusFranceSans, Times, serif',
      fontWeight: 400,
      lineHeight: 1.1,
      letterSpacing: 0,
      fontSize: '3.75rem',
    },
    button: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      '@media (max-width:960px)': {
        fontSize: '0.75rem',
      },
    },
    caption: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 0,
      textTransform: 'none',
      fontSize: '0.5625rem',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0, // < 600
      sm: 600, // < 960
      md: 960, //  < 1280
      lg: 1088, // < 1920
      xl: 1920, // -
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // height: '100%',
          scrollBehavior: 'smooth',
        },
      },
    },
  },
});

export default theme;
