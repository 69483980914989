export default theme => ({
  blockContainer: {
    padding: theme.spacing(8, 8),
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(5)} !important`,
      paddingRight: `${theme.spacing(5)} !important`,
    },
  },
  greenTitle: {
    '& b': {
      color: theme.palette.green.ultradark,
    },
  },
  green: {
    color: theme.palette.green.ultradark,
  },
  greenBackground: {
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.contrastText,
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  asideTitle: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  mapBlock: {
    width: '100%',
  },
  mapWrapper: {
    position: 'relative',
    width: '100%',
    paddingTop: '52.53%',
  },
  map: {
    position: 'absolute',
    objectFit: 'contain',
    inset: 0,
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  mapHover: {
    opacity: 1,
  },
  mapworld: {
    opacity: 1,
  },
  playBtn: {
    backgroundColor: `${theme.palette.green.ultradark} !important`,
    transition: 'background-color 0.3s ease-in-out',
    [theme.breakpoints.down('md')]: {
      width: '26px !important',
      height: '26px !important',
      minHeight: '26px !important',
    },
  },
  playIcon: {
    stroke: `${theme.palette.white.main} !important`,
    transition: 'stroke 0.3s ease-in-out',
  },
  hover: {
    backgroundColor: `${theme.palette.white.main} !important`,
    '& svg': {
      stroke: `${theme.palette.green.ultradark} !important`,
    },
  },
  africa: {
    position: 'absolute !important',
    left: '50%',
    top: '60%',
  },
  america: {
    position: 'absolute !important',
    left: '25%',
    top: '50%',
  },
  anmo: {
    position: 'absolute !important',
    left: '45%',
    top: '45%',
  },
  asia: {
    position: 'absolute !important',
    left: '75%',
    top: '35%',
  },
  europa: {
    position: 'absolute !important',
    left: '50%',
    top: '30%',
  },
});
