import React from 'react';
import Hidden from '@mui/material/Hidden';
import { InView } from 'react-intersection-observer';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Burger from '@mui/icons-material/Menu';
import B00Intro from './blocks/B00Intro';
import B01Janvier from './blocks/B01Janvier';
import B02Fevrier from './blocks/B02Fevrier';
import B03Mars from './blocks/B03Mars';
import B04Avril from './blocks/B04Avril';
import B06Juin from './blocks/B06Juin';
import B05Mai from './blocks/B05Mai';
import B07Juillet from './blocks/B07Juillet';
import B08Aout from './blocks/B08Aout';
import B09Septembre from './blocks/B09Septembre';
import B10Octobre from './blocks/B10Octobre';
import B11Novembre from './blocks/B11Novembre';
import B12Decembre from './blocks/B12Decembre';

import TitleMonth from './components/TitleMonth';
import Header from './components/Header';
import Footer from './components/Footer';

import PopupSocial from './popups/PopupSocial';
import PopupChiffres from './popups/PopupChiffres';
import PopupCredits from './popups/PopupCredits';

import data from './utils/data';
import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DesktopMenu';

import styles from './styles';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupsState: {},
      hoversState: {},
      inViewState: {},
      overlayState: false,
      mobileNav: false,
    };
    this.onPopup = this.onPopup.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onView = this.onView.bind(this);
  }

  toggleOverlay(overlayState) {
    this.setState({ overlayState });
  }

  onPopup(id, state) {
    this.setState(prev => ({ popupsState: { ...prev.popupsState, [id]: state } }));
  }

  onHover(id, state) {
    this.setState(prev => ({ hoversState: { ...prev.hoversState, [id]: state } }));
  }

  onView(id, inView) {
    if (inView) {
      this.setState({ inViewState: { [id]: inView } });
    }
  }

  onMonthClick(id) {
    if (id === 'intro') {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(id);
      if (element && element.offsetTop) {
        window.scroll({ top: element.offsetTop, behavior: 'smooth' });
      }
    }
    this.setState({ mobileNav: false, overlayState: false });
  }

  render() {
    const { popupsState, hoversState, inViewState, overlayState, mobileNav } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Hidden mdDown>
          <DesktopMenu
            inViewStates={inViewState}
            onMonthClick={this.onMonthClick}
            toggleOverlay={this.toggleOverlay}
            overlayState={overlayState}
          />
        </Hidden>
        <Hidden lgUp>
          <Burger
            className={`${classes.burger} ${mobileNav ? classes.menuOpen : ''}`}
            color="primary"
            onClick={() => this.toggleOverlay(true)}
          />
          <MobileMenu
            inViewStates={inViewState}
            onMonthClick={this.onMonthClick}
            toggleOverlay={this.toggleOverlay}
            popupsState={popupsState}
            onPopup={this.onPopup}
            overlayState={overlayState}
          />
        </Hidden>
        <div className={classes.bgWhite}>
          <Header popupsState={popupsState} toggleOverlay={this.toggleOverlay} onPopup={this.onPopup} />
          <InView onChange={inView => this.onView('intro', inView)}>
            <B00Intro baseUrl={BASE_URL} popupsState={popupsState} onPopup={this.onPopup} />
          </InView>
          <InView onChange={inView => this.onView('janvier', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="janvier"
              color="greenultradark"
              colorDesc="greenlight"
              dataComponent={data.components.months.january}
            />
            <B01Janvier
              baseUrl={BASE_URL}
              hoversState={hoversState}
              onHover={this.onHover}
              popupsState={popupsState}
              onPopup={this.onPopup}
            />
          </InView>
          <InView onChange={inView => this.onView('fevrier', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="fevrier"
              color="purpleultradark"
              colorDesc="purple"
              dataComponent={data.components.months.february}
            />
            <B02Fevrier baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('mars', inView)}>
            <TitleMonth
              anchor="mars"
              baseUrl={BASE_URL}
              color="pinkdark"
              colorDesc="pink"
              dataComponent={data.components.months.march}
            />
            <B03Mars baseUrl={BASE_URL} popupsState={popupsState} onPopup={this.onPopup} />
          </InView>
          <InView onChange={inView => this.onView('avril', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="avril"
              color="blueultradark"
              colorDesc="bluelight"
              dataComponent={data.components.months.april}
            />
            <B04Avril baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('mai', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="mai"
              color="orange"
              colorDesc="orangelight"
              dataComponent={data.components.months.may}
            />
            <B05Mai baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('juin', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="juin"
              color="green"
              colorDesc="greenultralight"
              dataComponent={data.components.months.june}
            />
            <B06Juin baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('juillet', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="juillet"
              color="pinklight"
              colorDesc="pinkultralight"
              dataComponent={data.components.months.july}
            />
            <B07Juillet baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('aout', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="aout"
              color="purpledark"
              colorDesc="purplelight"
              dataComponent={data.components.months.august}
            />
            <B08Aout baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('septembre', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="septembre"
              color="ocherlight"
              colorDesc="ocherultralight"
              isBig
              dataComponent={data.components.months.september}
            />
            <B09Septembre baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('octobre', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="octobre"
              color="bluedark"
              colorDesc="bluemedium"
              dataComponent={data.components.months.october}
              backgroundAnimationParams={{
                images: data.blocks.b10octobre.animationImages,
                order: data.blocks.b10octobre.animationOrder,
                timing: data.blocks.b10octobre.animationTiming,
              }}
            />
            <B10Octobre baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('novembre', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="novembre"
              color="greendark"
              colorDesc="greenapple"
              isBig
              dataComponent={data.components.months.november}
            />
            <B11Novembre baseUrl={BASE_URL} />
          </InView>
          <InView onChange={inView => this.onView('decembre', inView)}>
            <TitleMonth
              baseUrl={BASE_URL}
              anchor="decembre"
              color="blueultralight"
              colorDesc="ocher"
              isBig
              dataComponent={data.components.months.december}
            />
            <B12Decembre baseUrl={BASE_URL} />
          </InView>
          <PopupSocial open={!!popupsState.social} onClose={() => this.onPopup('social', false)} />
          <PopupChiffres open={!!popupsState.chiffres} onClose={() => this.onPopup('chiffres', false)} />
          <PopupCredits
            open={!!popupsState.credits}
            onClose={() => this.onPopup('credits', false)}
            popupsState={popupsState}
            popupData={data.common.credits}
            onPopup={this.onPopup}
          />
          <Footer popupsState={popupsState} onPopup={this.onPopup} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
