import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ReactPlayer from 'react-player';

import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as Play } from '../../res/play.svg';

import styles from './styles';

class VideoDialog extends React.PureComponent {
  render() {
    const { classes, url, open, onClose } = this.props;
    return (
      <Backdrop open={open || false} onClick={onClose}>
        <Dialog
          maxWidth="md"
          fullWidth
          scroll="paper"
          open={open || false}
          onClose={onClose}
          PaperProps={{ square: true, className: classes.dialogPaper }}
        >
          <Fab color="primary" onClick={onClose} className={classes.closeIcon}>
            <CloseIcon fontSize="large" />
          </Fab>
          <DialogContent className={classes.dialogContent}>
            <ReactPlayer
              url={url}
              playIcon={<Play className={classes.playIcon} />}
              width="100%"
              height="100%"
              playing
              controls
              className={classes.player}
            />
          </DialogContent>
        </Dialog>
      </Backdrop>
    );
  }
}

VideoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  open: PropTypes.bool,
  url: PropTypes.string.isRequired,
  // Funcs
  onClose: PropTypes.func,
};

export default withStyles(styles)(VideoDialog);
