import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { Box, Fab, Stack, Container, Typography } from '@mui/material';
import PopupAfrica from '../../popups/PopupAfrica';
import PopupAmerica from '../../popups/PopupAmerica';
import PopupANMO from '../../popups/PopupANMO';
import PopupAsia from '../../popups/PopupAsia';
import PopupEuropa from '../../popups/PopupEuropa';

import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';
import { ReactComponent as Add } from '../../res/add.svg';

class B01Janvier extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b01janvier;
    this.dataPopupAfrica = data.popups.africa;
    this.dataPopupAmerica = data.popups.america;
    this.dataPopupANMO = data.popups.anmo;
    this.dataPopupAsia = data.popups.asia;
    this.dataPopupEuropa = data.popups.europa;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderTextSection(content, title = null, src = null, customWidth = '50%') {
    const { classes } = this.props;
    return (
      <Stack sx={{ width: { xs: '100%', md: customWidth } }} direction="column" spacing={2}>
        {title && (
          <Typography key="title" className={classes.purple}>
            <b>{title}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {content}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
        {src && <img className={classes.icon} src={`${this.imgBase}${src}`} alt="" />}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={`${classes.blockContainer} ${classes.greenTitle}`}>
        <Stack direction="column" spacing={3}>
          <Typography className={classes.green} variant="h5" align="center">
            {this.dataBlock.title}
          </Typography>
          <Typography>
            <b>{this.dataBlock.topSubtitle}</b>
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextSection(this.dataBlock.left, null, null)}
            {this.renderTextSection(this.dataBlock.right, null, null)}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderMap() {
    const { classes, onHover, hoversState, onPopup } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <div className={classes.mapBlock}>
          <div className={classes.mapWrapper}>
            <img className={`${classes.map} ${classes.mapworld}`} src={`${this.imgBase}${this.dataBlock.map}`} alt="" />
            <img
              className={`${classes.map} ${classes.mapafrica} ${
                hoversState[this.dataBlock.hoverAfrica] ? classes.mapHover : null
              }`}
              src={`${this.imgBase}${this.dataBlock.mapafrica}`}
              alt=""
            />
            <img
              className={`${classes.map} ${classes.mapamerica} ${
                hoversState[this.dataBlock.hoverAmerica] ? classes.mapHover : null
              }`}
              src={`${this.imgBase}${this.dataBlock.mapamerica}`}
              alt=""
            />
            <img
              className={`${classes.map} ${classes.mapanmo} ${
                hoversState[this.dataBlock.hoverAnmo] ? classes.mapHover : null
              }`}
              src={`${this.imgBase}${this.dataBlock.mapanmo}`}
              alt=""
            />
            <img
              className={`${classes.map} ${classes.mapasia} ${
                hoversState[this.dataBlock.hoverAsia] ? classes.mapHover : null
              }`}
              src={`${this.imgBase}${this.dataBlock.mapasia}`}
              alt=""
            />
            <img
              className={`${classes.map} ${classes.mapeuropa} ${
                hoversState[this.dataBlock.hoverEuropa] ? classes.mapHover : null
              }`}
              src={`${this.imgBase}${this.dataBlock.mapeuropa}`}
              alt=""
            />
            <Fab
              color="primary"
              onMouseEnter={() => onHover(this.dataBlock.hoverAfrica, true)}
              onMouseLeave={() => onHover(this.dataBlock.hoverAfrica, false)}
              onClick={() => onPopup(this.dataBlock.popupAfricaTrigger, true)}
              className={`${classes.playBtn} ${classes.africa} ${
                hoversState[this.dataBlock.hoverAfrica] ? classes.hover : null
              }`}
            >
              <Add className={classes.playIcon} />
            </Fab>
            <Fab
              color="primary"
              onMouseEnter={() => onHover(this.dataBlock.hoverAmerica, true)}
              onMouseLeave={() => onHover(this.dataBlock.hoverAmerica, false)}
              onClick={() => onPopup(this.dataBlock.popupAmericaTrigger, true)}
              className={`${classes.playBtn} ${classes.america} ${
                hoversState[this.dataBlock.hoverAmerica] ? classes.hover : null
              }`}
            >
              <Add className={classes.playIcon} />
            </Fab>
            <Fab
              color="primary"
              onMouseEnter={() => onHover(this.dataBlock.hoverAsia, true)}
              onMouseLeave={() => onHover(this.dataBlock.hoverAsia, false)}
              onClick={() => onPopup(this.dataBlock.popupAsiaTrigger, true)}
              className={`${classes.playBtn} ${classes.asia} ${
                hoversState[this.dataBlock.hoverAsia] ? classes.hover : null
              }`}
            >
              <Add className={classes.playIcon} />
            </Fab>
            <Fab
              color="primary"
              onMouseEnter={() => onHover(this.dataBlock.hoverAnmo, true)}
              onMouseLeave={() => onHover(this.dataBlock.hoverAnmo, false)}
              onClick={() => onPopup(this.dataBlock.popupANMOTrigger, true)}
              className={`${classes.playBtn} ${classes.anmo} ${
                hoversState[this.dataBlock.hoverAnmo] ? classes.hover : null
              }`}
            >
              <Add className={classes.playIcon} />
            </Fab>
            <Fab
              color="primary"
              onMouseEnter={() => onHover(this.dataBlock.hoverEuropa, true)}
              onMouseLeave={() => onHover(this.dataBlock.hoverEuropa, false)}
              onClick={() => onPopup(this.dataBlock.popupEuropaTrigger, true)}
              className={`${classes.playBtn} ${classes.europa} ${
                hoversState[this.dataBlock.hoverEuropa] ? classes.hover : null
              }`}
            >
              <Add className={classes.playIcon} />
            </Fab>
          </div>
        </div>
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={`${classes.blockContainer} ${classes.greenBackground}`}>
        <Stack direction="column" spacing={2}>
          <Typography className={classes.asideTitle} variant="h6">
            {parse(this.dataBlock.asideTitle)}
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextSection(this.dataBlock.asideLeft, null, null, '50%')}
            {this.renderTextSection(this.dataBlock.asideRight, null, this.dataBlock.asideImg, '50%')}
          </Stack>
        </Stack>
      </Container>
    );
  }

  render() {
    const { baseUrl, onPopup, popupsState } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }}>
        {this.renderTopPart()}
        {this.renderMap()}
        {this.renderBottomPart()}
        <PopupAfrica
          popupData={this.dataPopupAfrica}
          open={!!popupsState[this.dataBlock.popupAfricaTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupAfricaTrigger, false)}
        />
        <PopupANMO
          popupData={this.dataPopupANMO}
          open={!!popupsState[this.dataBlock.popupANMOTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupANMOTrigger, false)}
        />
        <PopupEuropa
          popupData={this.dataPopupEuropa}
          open={!!popupsState[this.dataBlock.popupEuropaTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupEuropaTrigger, false)}
        />
        <PopupAmerica
          popupData={this.dataPopupAmerica}
          open={!!popupsState[this.dataBlock.popupAmericaTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupAmericaTrigger, false)}
        />
        <PopupAsia
          popupData={this.dataPopupAsia}
          open={!!popupsState[this.dataBlock.popupAsiaTrigger]}
          baseUrl={baseUrl}
          onClose={() => onPopup(this.dataBlock.popupAsiaTrigger, false)}
        />
      </Box>
    );
  }
}

B01Janvier.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  onHover: PropTypes.func,
  hoversState: PropTypes.object,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B01Janvier);
