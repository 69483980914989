import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import VideoDialog from '../../popups/VideoDialog';
import { ReactComponent as Play } from '../../res/playRounded.svg';

import data from '../../utils/data';
import parse from '../../utils/parse';
import styles from './styles';

class B10octobre extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topVideoOpen: false,
      bottomVideoOpen: false,
    };

    this.dataBlock = data.blocks.b10octobre;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderVideoPart = this.renderVideoPart.bind(this);
  }

  renderVideoPart() {
    const { classes } = this.props;
    const { topVideoOpen, bottomVideoOpen } = this.state;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Typography sx={{ marginBottom: 4 }} className={classes.color} variant="h4" align="center">
          {this.dataBlock.rdvTitle}
        </Typography>
        <Stack direction="column">
          <Box sx={{ width: '100%' }}>
            <Stack
              direction={{
                xs: 'column-reverse',
                md: 'row',
              }}
            >
              <Stack sx={{ width: { xs: '100%', md: '40%' } }} direction="column">
                <Box sx={{ flex: 1 }}></Box>
                <Box className={`${classes.boxTop} ${classes.colorBackground}`}>
                  <Typography>{this.dataBlock.rdvText}</Typography>
                </Box>
              </Stack>
              <Box className={classes.topVideo} onClick={() => this.setState({ topVideoOpen: true })}>
                <Play className={classes.play} />
                <img className={classes.videoImage} src={`${this.imgBase}${this.dataBlock.rdvTopVideoImage}`} alt="" />
              </Box>
            </Stack>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box className={`${classes.boxBottom} ${classes.colorBackgroundDark}`}>
              <Typography sx={{ marginBottom: 2 }} variant="h6">
                {this.dataBlock.axeTitle}
              </Typography>
              <Stack direction="column" spacing={2}>
                {this.dataBlock.axeContent.map((c, idx) => (
                  <Typography key={idx}>{parse(c)}</Typography>
                ))}
              </Stack>
              <Button variant="contained" disableElevation className={classes.btnInvert}>
                <a href={this.dataBlock.axeButtonUrl} alt={this.dataBlock.axeButtonText} target="_blank">
                  {parse(this.dataBlock.axeButtonText)}
                </a>
              </Button>
            </Box>
          </Box>
          <Box className={classes.bottomVideo} onClick={() => this.setState({ bottomVideoOpen: true })}>
            <Play className={classes.play} />
            <img className={classes.videoImage} src={`${this.imgBase}${this.dataBlock.rdvBottomVideoImage}`} alt="" />
          </Box>
        </Stack>
        <VideoDialog
          open={topVideoOpen}
          url={this.dataBlock.rdvTopVideoUrl}
          onClose={() => this.setState({ topVideoOpen: false })}
        />
        <VideoDialog
          open={bottomVideoOpen}
          url={this.dataBlock.rdvBottomVideoUrl}
          onClose={() => this.setState({ bottomVideoOpen: false })}
        />
      </Container>
    );
  }

  renderInfoPart() {
    const { classes } = this.props;
    return (
      <Box sx={{ width: '100%', paddingBottom: { xs: 4 }, pl: '16px', pr: '16px' }} className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.secondaryBlockContainer}>
          <Typography sx={{ pb: '40px' }} className={classes.colorWhite} variant="h4" align="center">
            {parse(this.dataBlock.infoTitle)}
          </Typography>
          <Box className={classes.catalogueBoxContainer}>
            <Box className={classes.catalogueBoxContent}>
              <Typography className={classes.catalogueBoxContentTitle} align="center">
                <b>{parse(this.dataBlock.infoSubtitle)}</b>
              </Typography>
              <Stack
                sx={{ flexWrap: 'wrap', pt: '50px' }}
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                alignItems="flex-start"
                justifyContent="space-around"
                spacing={4}
              >
                {this.dataBlock.infoContent.map((c, idx) => (
                  <Stack
                    key={idx}
                    className={classes.catalogueBoxContentText}
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Typography variant="h5">{parse(c.title)}</Typography>
                    <Typography>{parse(c.desc)}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Box className={classes.catalogueWhiteBox}>
              <Typography sx={{ marginBottom: 2 }}>{parse(this.dataBlock.whiteBoxText)}</Typography>
              <Button variant="contained" disableElevation className={classes.btn}>
                <a href={this.dataBlock.whiteBoxButtonUrl} alt={this.dataBlock.whiteBoxButtonText} target="_blank">
                  {parse(this.dataBlock.whiteBoxButtonText)}
                </a>
              </Button>
            </Box>
            <Box sx={{ paddingBottom: { md: 8 } }}></Box>
          </Box>
        </Container>
      </Box>
    );
  }

  renderCataloguePartTextSection(options = {}, index = -1) {
    const { classes } = this.props;
    const { image, title, content, buttonText, buttonUrl } = options;
    return (
      <Stack
        key={index}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{ pl: '16px', pr: '16px' }}
        spacing={{ xs: 4, md: 8 }}
      >
        {image && <img className={classes.image} src={`${this.imgBase}${image}`} alt="" />}
        <Stack key={index} direction="column" spacing={2}>
          <Typography key="title">
            <b>{title}</b>
          </Typography>
          {typeof content === 'string' && (
            <Typography key="content" variant="body1">
              {parse(content)}
            </Typography>
          )}
          {Array.isArray(content) &&
            content.map((c, idx) => (
              <Typography key={idx} variant="body1">
                {parse(c)}
              </Typography>
            ))}
          <Button sx={{ marginTop: 4 }} variant="contained" disableElevation className={`${classes.btn}`}>
            <a href={buttonUrl} alt={buttonText} target="_blank">
              {parse(buttonText)}
            </a>
          </Button>
        </Stack>
      </Stack>
    );
  }

  renderCataloguePart() {
    const { classes } = this.props;
    return (
      <Box sx={{ width: '100%', paddingBottom: { xs: 4 } }} className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.secondaryBlockContainer}>
          <Typography sx={{ marginBottom: 4 }} variant="h5" align="center">
            {this.dataBlock.catalogueTitle}
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={{ xs: 8, md: 4 }}
          >
            {this.renderCataloguePartTextSection(
              {
                image: this.dataBlock.campusartImage,
                title: this.dataBlock.campusartTitle,
                content: this.dataBlock.campusarContent,
                buttonText: this.dataBlock.campusarButtonText,
                buttonUrl: this.dataBlock.campusarButtonUrl,
              },
              1,
            )}
            {this.renderCataloguePartTextSection(
              {
                image: this.dataBlock.formationImage,
                title: this.dataBlock.formationTitle,
                content: this.dataBlock.formationContent,
                buttonText: this.dataBlock.formationButtonText,
                buttonUrl: this.dataBlock.formationButtonUrl,
              },
              2,
            )}
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderVideoPart()}
        {this.renderInfoPart()}
        {this.renderCataloguePart()}
      </Box>
    );
  }
}

B10octobre.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B10octobre);
