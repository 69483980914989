export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),
    '& .dash': {
      color: theme.palette.purple.light,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  icon: {
    width: 76,
    height: 80,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: 55,
      height: 55,
    },
  },
  illus: {
    paddingTop: theme.spacing(2),
    width: '70%',
    maxHeight: 400,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      textAlign: 'center',
      display: 'block',
      margin: 'auto !important',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  purple: {
    color: theme.palette.purple.ultradark,
  },
  purpleBackground: {
    backgroundColor: theme.palette.purple.main,
    color: theme.palette.purple.contrastText,
  },
});
