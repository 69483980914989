import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B06Juin extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b06juin;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
  }

  renderTextTopSection(content, title = null, src = null, customWidth = '25%', isGreen = false) {
    const { classes } = this.props;
    return (
      <Stack
        sx={{
          width: {
            xs: '100%',
            md: customWidth,
          },
          p: ['30px', '64px', '64px'],
        }}
        direction="column"
        alignItems="flex-start"
        spacing={2}
        className={isGreen ? classes.greenTextSection : null}
      >
        {src && <img className={classes.icon} src={`${this.imgBase}${src}`} alt="" />}
        {title && (
          <Typography key="title" className={isGreen ? classes.white : classes.green}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderGreenSections(text, title, icon, image, imageRight = false, xsDirection = 'column-reverse') {
    const { classes } = this.props;
    const imagePosition = imageRight ? 'right' : 'left';

    return (
      <Stack
        direction={{
          xs: xsDirection,
          md: 'row',
        }}
        sx={{ pt: ['0px', '0px', '40px'] }}
        justifyContent="space-between"
        spacing={4}
      >
        {imageRight ? this.renderTextTopSection(text, title, icon, '55%', true) : null}
        <Stack sx={{ pt: ['0', '0', '40px'], width: ['100%', '100%', '40%'] }} className={classes[imagePosition]}>
          <img src={`${this.imgBase}${image}`} sx={{ width: ['50%', '80%', 'calc(100% + 50px)'] }} alt="" />
        </Stack>
        {imageRight ? null : this.renderTextTopSection(text, title, icon, '60%', true)}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column">
          <Typography
            sx={{ pr: '16px', pl: '16px', pb: { xs: '24px', md: '0px' } }}
            className={classes.green}
            variant="h5"
            align="center"
          >
            {parse(this.dataBlock.topTitle)}
          </Typography>
          {this.renderGreenSections(
            this.dataBlock.studyText,
            this.dataBlock.studyTitle,
            this.dataBlock.studyIcon,
            this.dataBlock.studyImage,
            false,
          )}
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pt: [0, '40px', '40px'] }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextTopSection(
              this.dataBlock.interregText,
              this.dataBlock.interregTitle,
              this.dataBlock.interregIcon,
              '50%',
            )}
            <Stack
              width="50%"
              sx={{ pt: ['20px', '20px', '40px'] }}
              justifyContent="center"
              className={classes.interregImgWeb}
              spacing={4}
            >
              <img src={`${this.imgBase}${this.dataBlock.interregImage}`} alt="" />
            </Stack>
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pt: ['70px', '200px', '40px'] }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextTopSection(
              this.dataBlock.hopesText,
              this.dataBlock.hopesTitle,
              this.dataBlock.hopesIcon,
              '50%',
            )}
            {this.renderTextTopSection(this.dataBlock.eapText, this.dataBlock.eapTitle, this.dataBlock.eapIcon, '50%')}
          </Stack>
          {this.renderGreenSections(
            this.dataBlock.meetingText,
            this.dataBlock.meetingTitle,
            null,
            this.dataBlock.meetingImage,
            true,
            'column',
          )}
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pt: { xs: 0, md: '40px' } }}
            justifyContent="space-between"
            spacing={{ xs: 0, md: 4 }}
          >
            {this.renderTextTopSection(this.dataBlock.seminarText, this.dataBlock.seminarTitle, null, '50%')}
            {this.renderTextTopSection(this.dataBlock.webinarText, this.dataBlock.webinarTitle, null, '50%')}
          </Stack>
        </Stack>
        <Typography
          sx={{ fontWeight: 'bold', pt: '40px', pb: ['40px', '80px', '0'], pr: '16px', pl: '16px' }}
          className={classes.green}
          variant="body1"
          align="center"
        >
          {parse(this.dataBlock.topEndTitle)}
        </Typography>
      </Container>
    );
  }

  renderTextBottomSection(content, customWidth = '50%') {
    const { classes } = this.props;
    return (
      <Stack
        sx={{
          width: {
            xs: '100%',
            md: customWidth,
          },
        }}
        direction="column"
        spacing={2}
      >
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1" className={classes.bottomContent}>
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Box className={classes.greenBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack direction="column" spacing={2}>
            <Typography className={classes.white} variant="h6" align="center">
              {parse(this.dataBlock.bottomTitle)}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {this.renderTextBottomSection(this.dataBlock.bottomLeftContent, '50%')}
              {this.renderTextBottomSection(this.dataBlock.bottomRightContent, '50%')}
            </Stack>
            <img className={classes.illustration} src={`${this.imgBase}${this.dataBlock.bottomRightImage}`} alt="" />
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B06Juin.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B06Juin);
