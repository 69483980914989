import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import PopupPage from '../PopupPage';

import SocialImage from '../../res/social-year.jpg';
import styles from './styles';

class PopupSocial extends React.Component {
  render() {
    const { classes, open, onClose } = this.props;
    return (
      <PopupPage key="social" open={open} onClose={onClose} closeIconColor="blue" className={classes.container}>
        <img src={SocialImage} className={classes.image} alt="Social" />
      </PopupPage>
    );
  }
}

PopupSocial.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(PopupSocial);
