import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B05Mai extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b05mai;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
    this.renderTextSection = this.renderTextSection.bind(this);
  }

  renderTextSection(options = {}, key = -1) {
    const { classes } = this.props;
    const { icon, title, content, color } = options;
    const typoClass = color ? classes.color : null;
    return (
      <Stack
        key={key}
        sx={{ width: { sm: '100%', md: options.width || '50%' }, pl: '16px', pr: '16px', pb: '24px' }}
        direction="column"
        spacing={2}
      >
        {icon && <img className={classes.icon} src={`${this.imgBase}${icon}`} alt="" />}
        {title && (
          <Typography key="title" className={typoClass}>
            <b>{title}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column" spacing={3}>
          <Typography sx={{ pl: '16px', pr: '16px' }} className={classes.color} variant="h4" align="center">
            {parse(this.dataBlock.title)}
          </Typography>
          <Typography sx={{ pl: '16px', pr: '16px' }}>
            <b>{parse(this.dataBlock.subtitle)}</b>
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextSection(
              {
                content: this.dataBlock.partnerContent,
                title: this.dataBlock.partnerTitle,
                icon: 'b05-partner.svg',
                color: true,
              },
              'partner',
            )}
            {this.renderTextSection(
              {
                content: this.dataBlock.auditionContent,
                title: this.dataBlock.auditionTitle,
                icon: 'b05-audition.svg',
                color: true,
              },
              'audition',
            )}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={`${classes.colorBackground} ${classes.blockContainer}`}>
        <Stack direction="column" spacing={2}>
          <Typography sx={{ textAlign: 'center' }} className={classes.white} variant="h6">
            {parse(this.dataBlock.programTitle)}
          </Typography>
          <Stack direction="column" spacing={{ xs: 2, md: 6 }}>
            <Stack
              key="malaisie"
              className={classes.countryBloc}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={{ xs: 2, md: 4 }}
            >
              {this.renderTextSection(
                {
                  content: this.dataBlock.malaisieText,
                  title: this.dataBlock.malaisieTitle,
                },
                'malaisie',
              )}
              <img className={classes.image} src={`${this.imgBase}${this.dataBlock.malaisieImage}`} alt="" />
            </Stack>
            <Stack
              key="alula"
              className={classes.countryBloc}
              direction={{
                xs: 'column-reverse',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              <img className={classes.image} src={`${this.imgBase}${this.dataBlock.alulaImage}`} alt="" />
              {this.renderTextSection(
                {
                  content: this.dataBlock.alulaText,
                  title: this.dataBlock.alulaTitle,
                },
                'alula',
              )}
            </Stack>
            <Stack
              sx={{ pb: '24px' }}
              key="bid"
              className={classes.countryBloc}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {this.renderTextSection(
                {
                  content: this.dataBlock.bidText,
                  title: this.dataBlock.bidTitle,
                  width: '100%',
                },
                'bid',
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B05Mai.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B05Mai);
