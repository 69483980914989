import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Stack, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PopupPage from '../PopupPage';

import styles from './styles';

class PopupAfrica extends React.Component {
  renderTextSection(content, index) {
    const { classes, baseUrl } = this.props;
    const imgBase = `${baseUrl}/popups/`;
    return (
      <Stack key={index} sx={{ width: { sm: '100%', md: '50%' } }} direction="column" spacing={2}>
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <React.Fragment key={idx}>
              <Typography key="title" variant="body1" className={classes.title}>
                {c.title && parse(c.title)}
              </Typography>
              <Typography key="text" variant="body1" className={classes.text}>
                {c.text && parse(c.text)}
              </Typography>
              {c.image && <img className={classes.illustration} src={`${imgBase}${c.image}`} alt="" />}
            </React.Fragment>
          ))}
      </Stack>
    );
  }

  render() {
    const { popupData, classes, open, onClose } = this.props;
    return (
      <PopupPage key="africa" open={open} onClose={onClose} closeIconColor="green" className={classes.container}>
        <Stack key="stack01" direction="column" className={classes.content}>
          <Typography key="title" variant="h5" align="center" className={classes.mainTitle}>
            <b>{parse(popupData.title)}</b>
          </Typography>
          <Stack
            key="text"
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
          >
            {this.renderTextSection(popupData.column1, 'text01')}
            {this.renderTextSection(popupData.column2, 'text02')}
          </Stack>
          <Stack key="images" sx={{ marginTop: 2 }} direction="column" spacing={2}>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              spacing={2}
            ></Stack>
          </Stack>
        </Stack>
      </PopupPage>
    );
  }
}

PopupAfrica.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  popupData: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(PopupAfrica);
