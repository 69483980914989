export default theme => ({
  blockContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(32),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      overflow: 'hidden',
      backgroundColor: theme.palette.white.main,
      position: 'relative',
      marginTop: 436,
    },
  },
  header: {
    position: 'relative',
    zIndex: 1001,
    width: '100%',
    margin: 'auto',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      top: 0,
      zIndex: -1,
    },
  },
  headerWrapper: {
    position: 'relative',
    maxWidth: 1400,
    margin: 'auto',
    backgroundColor: theme.palette.white.main,
    width: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      '&.desktop': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      '&.mobile': {
        display: 'none',
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },
    },
  },
  headerTitle: {
    position: 'absolute',
    bottom: 60,
    left: '4%',
    color: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      textIndent: 0,
      bottom: '3%',
      left: '8%',
      color: theme.palette.white.main,
      fontSize: '2rem !important',
    },
  },
  ocher: {
    color: theme.palette.ocher.main,
  },
  blue: {
    color: theme.palette.blue.dark,
  },
  edito: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      fontSize: 0,
    },
  },
  rv: {
    position: 'relative',
    height: 450,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      height: 'initial',
      marginBottom: theme.spacing(13),
    },
  },
  rvTitle1: {
    position: 'relative',
    zIndex: 3,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(16),
    lineHeight: '1 !important',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      padding: 'initial !important',
      fontSize: '2rem !important',
      textAlign: 'center',
    },
  },
  rvTitle2: {
    position: 'relative',
    zIndex: 3,
    paddingBottom: theme.spacing(8),
    lineHeight: '1 !important',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      padding: 'initial !important',
      fontSize: '2.5rem !important',
      textAlign: 'center',
      paddingBottom: 0,
    },
  },
  rvIllus1: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    transform: 'translateX(100%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rvIllus2: {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    position: 'relative',
    backgroundColor: theme.palette.blue.bg,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  quoteAuthor: {
    display: 'inline-block',
    paddingBottom: theme.spacing(1),
  },
  quotecontent: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(4),
      paddingTop: theme.spacing(14),
    },
  },
  quoteBtn: {
    marginTop: `${theme.spacing(2)} !important`,
    borderRadius: '0 !important',
    [theme.breakpoints.down('md')]: {
      display: 'block !important',
      margin: 'auto !important',
      marginTop: `${theme.spacing(2)} !important`,
    },
  },
  '@keyframes spinrv': {
    from: { transform: 'translateY(-60%) rotate(0deg)' },
    to: { transform: 'translateY(-60%) rotate(360deg)' },
  },
  quoteLogo: {
    position: 'absolute',
    zIndex: 2,
    left: 150,
    top: 0,
    transform: 'translateY(-60%)',
    // animation: '$spinrv 20s infinite linear',
    [theme.breakpoints.down('md')]: {
      top: 16,
      left: 100,
    },
  },
  quoteImgContainer: {
    position: 'absolute',
    bottom: 0,
    right: '3%',
    transform: 'translateY(50%)',
    border: '4px solid #FFFFFF',
    borderRadius: '100%',
    overflow: 'hidden',
    width: 300,
    height: 300,
    [theme.breakpoints.down('md')]: {
      width: 'initial',
      height: 'initial',
      border: 'initial',
      position: 'initial',
      transform: 'initial',
      borderRadius: 'initial',
    },
  },
  quoteImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  tenYears: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: 0,
      flexDirection: 'column',
      '& > div, & img': {
        width: '100%',
      },
    },
  },
  tenYearsText: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tenYearsTitle1: {
    textAlign: 'right',
  },
  tenYearsTitle2: {
    textAlign: 'right',
  },
  tenYearsImg2Wrapper: {
    position: 'absolute',
    bottom: -200,
    left: '30%',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      bottom: 0,
      left: 0,
      fontSize: 0,
    },
  },
  '@keyframes spin10years': {
    from: { transform: 'translate(80%, -60%) rotate(0deg)' },
    to: { transform: 'translate(80%, -60%) rotate(360deg)' },
  },
  tenYearsLogo: {
    position: 'absolute',
    top: 0,
    right: 0,
    // animation: '$spin10years 20s infinite linear',
    [theme.breakpoints.down('md')]: {
      width: '213px !important',
      right: 56,
      top: -213,
    },
  },
  caption: {
    paddingTop: theme.spacing(0.5),
    maxWidth: 250,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});
