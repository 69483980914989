export default theme => ({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  content: {
    padding: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
    '& .title': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    '& .credit': {
      display: 'block',
      fontWeight: 'bold',
    },
  },
  mainTitle: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.blue.dark,
  },
});
