import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B07Juillet extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b07juillet;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
  }

  renderTextTopSection(content, title = null, customWidth = '100%') {
    const { classes } = this.props;
    return (
      <Stack
        sx={{
          width: {
            xs: '100%',
            md: customWidth,
          },
        }}
        direction="column"
        alignItems="flex-start"
        spacing={2}
      >
        {title && (
          <Typography key="title" className={classes.pink}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column" spacing={3}>
          <Typography sx={{ pl: '16px', pr: '16px' }} className={classes.pink} variant="h5" align="center">
            {parse(this.dataBlock.topTitle)}
          </Typography>
          <Typography sx={{ pl: '16px', pr: '16px', fontWeight: 'bold' }} variant="body1" align="center">
            {parse(this.dataBlock.topSubtitle)}
          </Typography>
          <Stack
            className={classes.mobilePadding}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pt: '40px' }}
            justifyContent="space-between"
            spacing={4}
          >
            <Stack
              sx={{ width: ['100%', '100%', '50%'] }}
              direction="column"
              justifyContent="space-between"
              spacing={4}
            >
              {this.renderTextTopSection(this.dataBlock.actionsText, this.dataBlock.actionsTitle)}
              {this.renderTextTopSection(this.dataBlock.maakumText, this.dataBlock.maakumTitle)}
            </Stack>
            <Stack sx={{ width: ['100%', '100%', '50%'] }} spacing={4}>
              <img src={`${this.imgBase}${this.dataBlock.illustration1}`} alt="" />
            </Stack>
          </Stack>
          <Stack
            className={classes.mobilePadding}
            direction={{
              xs: 'column-reverse',
              md: 'row',
            }}
            sx={{ pt: '40px' }}
            justifyContent="space-between"
            spacing={4}
          >
            <Stack sx={{ width: ['100%', '100%', '50%'] }} spacing={4}>
              <img src={`${this.imgBase}${this.dataBlock.illustration2}`} alt="" />
            </Stack>
            <Stack
              sx={{ width: ['100%', '100%', '50%'] }}
              direction="column"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack direction="row" spacing={1}>
                {this.renderTextTopSection(this.dataBlock.partnerText, this.dataBlock.partnerTitle)}
                <img src={`${this.imgBase}${this.dataBlock.partnerImage}`} className={classes.logo} alt="" />
              </Stack>
              {this.renderTextTopSection(this.dataBlock.scholarshipText, this.dataBlock.scholarshipTitle)}
              {this.renderTextTopSection(this.dataBlock.elyseeText, this.dataBlock.elyseeTitle)}
            </Stack>
          </Stack>
          <Stack
            className={classes.mobilePadding}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{ pt: '40px' }}
            justifyContent="space-between"
            spacing={4}
          >
            {this.renderTextTopSection(this.dataBlock.hopesText, this.dataBlock.hopesTitle, '50%')}
            {this.renderTextTopSection(this.dataBlock.webinarText, this.dataBlock.webinarTitle, '50%')}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderTextBottomSection(content, customWidth = '40%') {
    const { classes } = this.props;
    return (
      <Stack
        sx={{
          width: {
            xs: '100%',
            md: customWidth,
          },
        }}
        direction="column"
        spacing={2}
      >
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1" className={classes.bottomContent}>
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Box className={classes.pinkBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack direction="column" spacing={2}>
            <Typography className={classes.white} variant="h6" align="center">
              {parse(this.dataBlock.bottomTitle)}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="center"
              spacing={4}
            >
              {this.renderTextBottomSection(this.dataBlock.bottomLeftContent, '40%')}
              {this.renderTextBottomSection(this.dataBlock.bottomRightContent, '40%')}
            </Stack>
          </Stack>
        </Container>
        <Container maxWidth="lg" className={classes.imgContainer}>
          <img className={classes.image} src={`${this.imgBase}${this.dataBlock.bottomImage}`} alt="" />
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box
        sx={{ backgroundColor: theme => theme.palette.white.main }}
        className={`${classes.blockContainer} ${classes.main}`}
      >
        {this.renderTopPart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B07Juillet.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B07Juillet);
