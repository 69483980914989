export default {
  common: {
    mainTitle: 'Rapport Annuel',
    headerChiffresCle: 'Chiffres-clés',
    headerReseaux: 'Réseaux sociaux 2021',
    headerDownload: 'Télécharger le rapport annuel',
    footerReturn: 'Retour au site',
    footerReturnLink: 'https://www.campusfrance.org/fr',
    footerCredit: 'Crédits',
    pdf: 'CAM-RA2021-INT-EP6.pdf',
    credits: {
      title: 'Credits',
      content: [
        '<span class="title">Rendez-vous en France</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Rencontres Campus France 2021 <span class="credit">© Campus France</span>',
        'Soirée à l’Hôtel du ministre des affaires étrangères en présence des alumni Campus France<span class="credit">© Campus France</span>',
        '<span class="title">Janvier</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        '<span class="title">Février</span>',
        'Visuels de la campagne de communication Rendez-vous en France <span class="credit">© Vincent Bourilhon</span>',
        'Sagar Rajesh Gangwani, BGF,au Château Vaux-le-Vicomte, décembre<span class="credit">© Campus France</span>',
        '<span class="title">Mars</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Séminaire de travail MOPGA, en présence du Président de la République, 2019.<span class="credit">© Présidence de la République</span>',
        '<span class="title">Avril</span>',
        'Visuels de la campagne de communication Rendez-vous en France <span class="credit">© Vincent Bourilhon</span>',
        'Labellisation de l’Espace Campus France au Mozambique en présence des premier alumni de 1988 et 1992, octobre<span class="credit">© Campus France</span>',
        'Le ministre Jean-Yves Le Drian rencontre des alumni ambassadeurs lors de sa visite en Inde, avril<span class="credit">© Institut Français en Inde</span>',
        'Rencontres France Alumni Palestine, décembre<span class="credit">© Campus France</span>',
        '<span class="title">Mai</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        '<span class="title">Juin</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Accueil des boursiers du gouvernement Malaisien à l’aéroport de Lyon Saint-Exupéry assuré par le Cavilam de Vichy et Campus France, en présence du Conseiller pour le suivi des étudiants, Ambassade de Malaisie en France<span class="credit">© Campus France</span>',
        'Visite d’une délégation de la RCUpour Al-Ula, octobre<span class="credit">© Campus France</span>',
        'Réunion « International Éducation Leadership », juin<span class="credit">© Campus France</span>',
        'Séminaire Accueil des Boursiers, septembre<span class="credit">© Campus France</span>',
        '<span class="title">Juillet</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Cocktail d’accueil des étudiants libanais à la Maison du Liban à la CIUP, octobre<span class="credit">© Campus France</span>',
        'Béatrice Khaiat à l’ambassade du Liban à Paris avec l’ambassadeur Rami Adwan, avril<span class="credit">© Campus France</span>',
        'Atelier virtuel des Rencontres Campus France sur les pays en crise : Afghanistan, Liban et Haïti, novembre.Olivier Huynh - Van, COCAC, Ambassade de France en Afghanistan et Olivier Chiche - Portiche, Directeur de la Coordination Géographique, Campus France, novembre<span class="credit">© Campus France</span>',
        '<span class="title">Août</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Emmanuelle Garnier, Présidente du Forum Campus France, Présidente de l’Université Toulouse Jean-Jaurès<span class="credit">Crédits : Université Toulouse Jean-Jaurès</span>',
        '<span class="title">Septembre</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        '<span class="title">Octobre</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        '<span class="title">Novembre</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        '10 ans d’attractivité, interview de Béatrice Khaiat à Newstank<span class="credit">© Campus France</span>',
        'Atelier des Rencontres Campus France bilan et perspectives – Zone géographique Amérique latine en présence de Marie - Pierre Macian, Chargée de mission - Amérique latine et Caraïbes - MESRI        (DAEI) et de l’équipe Campus France Amériques, novembre<span class="credit">© Campus France</span>',
        '<span class="title">Décembre</span>',
        'Visuels de la campagne de communication Rendez-vous en France<span class="credit">© Vincent Bourilhon</span>',
        'Séminaire d’Attractivité Europe à Budapest, septembre<span class="credit">© Campus France</span>',
        'Séminaire d’Attractivité Europe à Budapest, septembre<span class="credit">© Campus France</span>',
        'Présentation de la feuille de route de l’influence par Jean-Yves Le Drian, ministre de l’Europe et des Affaires étrangères, décembre<span class="credit">© Campus France</span>',
        'Thierry Coulhon, président du collège du Hcéres <span class="credit">Crédits : HCERES</span>',
      ],
    },
  },
  blocks: {
    b00intro: {
      headerImg: 'b00-header.png',
      headerImgMobile: 'b00-mobile-header.png',
      headerTitle: 'RAPPORT<br />D’ACTIVITÉ<br />2021',
      title1: 'RENDEZ - VOUS',
      title2: 'EN FRANCE',
      rvImg1: 'b00-rv1.png',
      rvImg2: 'b00-rv2.png',
      quoteAuthor: 'BÉATRICE KHAIAT, DIRECTRICE GÉNÉRALE',
      quote:
        '&#171;&nbsp;L’année 2021, qui a marqué les dix ans de la création de l’EPIC Campus France, nous a permis de mesurer à quel point l’Agence a été réactive et créative.&nbsp;&#187;',
      quoteBtn: 'Lire +',
      quotePopupTrigger: 'popupCeoTrigger',
      quoteLogo: 'b00-rvlogo.svg',
      quoteImg: 'b00-beatricekhaiat2.png',
      tenYearsLogo: 'b00-tenyearslogo.svg',
      tenYearsImg1: 'b00-tenyears1.png',
      tenYearsImg1Caption:
        'Soirée à l’Hôtel du ministre des affaires étrangères en présence des alumni des campagnes de communication Campus France',
      tenYearsImg2: 'b00-tenyears2.png',
      tenYearsImg2Caption: 'Rencontres Campus France 2021',
      tenYearsTitle1: 'Campus France',
      tenYearsTitle2: 'a 10 ans',
    },
    b01janvier: {
      title: 'LA PROMOTION DE L’ENSEIGNEMENT SUPÉRIEUR FRANÇAIS FACE À LA CRISE SANITAIRE',
      left: [
        '<b>UN RYTHME SOUTENU</b>',
        '2021 marque un retour à une programmation intense, retrouvant presque le niveau d’avant crise. On compte 58 manifestations réalisées, contre 45 en 2020 et 61 en 2019.',
        '<b>UNE FORTE REPRISE</b>',
        'L’année 2021 enregistre une hausse du nombre d’établissements ayant participé au moins une fois aux actions de promotion&nbsp;: 370 contre 339 en 2020. Cette hausse est portée essentiellement par les universités (500 contre 249 en 2020) et les écoles d’ingénieur (551 écoles contre 408 en 2020). À noter également une percée des écoles d’art (+14&nbsp;%) et des organismes de recherche (+20&nbsp;%).',
      ],
      right: [
        '<b>DE NOUVELLES PRIORITÉS</b>',
        'Campus France a fixé des priorités&nbsp;: l’Asie - et notamment la zone Indopacifique - l’Amérique latine et l’Afrique, avec une attention portée aux grands pays émergents, mais aussi l’Europe dans la perspective de la Présidence Française du Conseil de l’Union Européenne en janvier 2022.',
      ],
      map: 'b01-map.svg',
      mapafrica: 'b01-mapafrica.svg',
      mapamerica: 'b01-mapamerica.svg',
      mapanmo: 'b01-mapanmo.svg',
      mapasia: 'b01-mapasia.svg',
      mapeuropa: 'b01-mapeuropa.svg',
      hoverAfrica: 'hoverAfrica',
      hoverAmerica: 'hoverAmerica',
      hoverAsia: 'hoverAsia',
      hoverAnmo: 'hoverAnmo',
      hoverEuropa: 'hoverEuropa',
      popupAfricaTrigger: 'popupAfrica',
      popupAmericaTrigger: 'popupAmerica',
      popupAsiaTrigger: 'popupAsia',
      popupAnmoTrigger: 'popupAnmo',
      popupEuropaTrigger: 'popupEuropa',
      asideTitle: 'LES CAMPUS FRANCOÉTRANGERS&nbsp;:<br/>UNE DYNAMIQUE POUR L’AFRIQUE',
      asideLeft: [
        'La promotion des campus franco-étrangers a été confiée à Campus France par le MEAE.',
        '<b>WEBINAIRE DE LANCEMENT DU DEUXIÈME APPEL À PROJETS DU CAMPUS FRANCO-SÉNÉGALAIS, FÉVRIER</b>',
        'Ce webinaire porte sur le lancement de l’appel à projets pour la mise en place de nouvelles formations auprès des établissements français membres du Forum Campus France et des établissements sénégalais, soit plus de 150 participants.',
      ],
      asideRight: [
        '<b>CAMPUS FRANCE LIVE UFE, JUIN</b>',
        'Ce Campus France Live promeut les formations en français et en anglais proposées par L’Université Française d’Egypte (UFE), devenue la référence de l’enseignement supérieur français en Égypte.',
      ],
      asideImg: 'b03-aside.png',
    },
    b02fevrier: {
      topTitle: 'LE PROGRAMME D’EXCELLENCE EIFFEL',
      topSubtitle:
        'Le programme de bourses d’excellence Eiffel permet d’attirer les meilleurs étudiants internationaux, en France, en master et doctorat. En 2021, ce sont 347 candidats qui ont été retenus sur 1&nbsp;478 dossiers évalués.',
      stepIcon: 'b2-step-icon.svg',
      stepTitle: 'LES GRANDES ÉTAPES DE LA SÉLECTION 2021',
      stepContent: [
        '<span className="dash">—</span> Janvier&nbsp;: Lancement de la campagne',
        '<span className="dash">—</span> Mars&nbsp;: Début des commissions de sélection',
        '<span className="dash">—</span> Juin&nbsp;: Clôture, bilan et pistes d’amélioration du programme',
      ],
      stepIllus: 'b2-step-illus.jpg',
      bilanIcon: 'b2-bilan-icon.svg',
      bilanTitle: 'BILAN',
      bilanContent: [
        'Campus France a présenté les résultats d’une étude sur l’impact de ce programme dans la stratégie internationale des établissements et la trajectoire des anciens boursiers Eiffel.',
        '<span className="dash">—</span> Pour 84&nbsp;% d’entre eux, c’est un outil majeur de recrutement des étudiants d’excellence et un vecteur de développement de la stratégie de coopération internationale&nbsp;;',
        '<span className="dash">—</span> 91&nbsp;% des alumni ont conservé un lien fort avec la France&nbsp;;',
        '<span className="dash">—</span> 52&nbsp;% ont gardé des liens avec leur établissement&nbsp;; ',
        '<span className="dash">—</span> 77&nbsp;% de ceux ayant connaissance du réseau France Alumni y sont inscrits.',
      ],
      bottomTitle: 'LA SATISFACTION DES BOURSIERS EN QUELQUES CHIFFRES',
      bottomLeftContent: [
        '<span className="dash">—</span>',
        '2019 - 2020&nbsp;: + de 10&nbsp;000 bourses.',
        '<span className="dash">—</span>',
        'Taux de satisfaction en 2019 - 2020&nbsp;:',
        '- Séjour global&nbsp;: <b>98&nbsp;%</b>',
        '- Conseils pratiques&nbsp;: En 5 ans, augmentation <b>de 81&nbsp;% à 93&nbsp;%</b>',
        '- Accueil en France&nbsp;: <b>91&nbsp;%</b>',
        'Difficultés&nbsp;: la recherche de logement et les procédures administratives.',
        '- La qualité de la vie et des études en France&nbsp;: <b>97&nbsp;% et 95&nbsp;%</b>',
      ],
      bottomRightContent: [
        '<span className="dash">—</span>',
        'Seulement 30&nbsp;% des BGF seraient venus en France sans bourse',
        '<span className="dash">—</span>',
        '96&nbsp;% des BGF recommandent de s’adresser aux Espaces Campus France pour la préparation de leur séjour.',
        '<span className="dash">—</span>',
      ],
    },
    b03mars: {
      promotionTitle: 'LA PROMOTION<br> DE LA RECHERCHE',
      promotionContent: [
        {
          icon: 'b03-book.png',
          title: 'UN PORTAIL EN LIGNE LA RECHERCHE EN FRANCE',
          text: 'Ce catalogue publie plus de 2&nbsp;000 offres de thèse ouvertes aux étudiants internationaux. Il s’enrichit grâce à de nouveaux partenaires grâce notamment à une coopération avec l’ADUM (Accès Doctorat Unique Mutualisé)&nbsp;; ou encore la collaboration avec l’Association Bernard Gregory et le Réseau français des instituts d’études avancées.',
          illus: 'b03-research.png',
        },
        {
          icon: 'b03-star.png',
          title: 'LA PROMOTION DU DOCTORAT',
          text: 'Le doctorat et la recherche en France, formation en ligne pour les Espaces, s’est enrichi d’un niveau intermédiaire en 2021.<br/><br/>Des opérations ont été menées telles que Your PhD in France (République Tchèque, Pologne, Autriche, Slovaquie, Hongrie, janvier 2021), Tu Doctorado en Francia (Mexique, mars 2021) ou encore les tournées doctorales (Israël, Pakistan, Inde)&nbsp;;<br/><br/> Enfin, l’Actu Recherche est envoyée mensuellement à 17 000 destinataires des établissements d’enseignement supérieur et de recherche français et l’Agence édite plusieurs publications dédiées à la recherche.',
        },
        {
          icon: 'b03-nucleus.png',
          title: 'LA COMMISSION RECHERCHE',
          text: 'Cette commission du Forum Campus France permet l’échange avec l’ensemble de la communauté scientifique sur des sujets liés à la promotion de la recherche et à l’attractivité des chercheurs. Certaines de ses recommandations ont été reprises dans la Loi de Programmation de la Recherche.',
        },
      ],
      programsTitle: '2 PROGRAMMES',
      programsContent: [
        {
          icon: 'b03-logomopga.png',
          text: '<b>Le Programme MOPGA (Make Our Planet Great Again)</b><br/><br/>Lancé en 2017 par le Président de la République française<br/><br/><b>4 volets MOPGA</b> et 8 appels à candidatures depuis 2018<br/><br/><b>1 229</b> candidatures dont <b>36</b>% de femmes et <b>64</b>% d’hommes<br/><br/><b>218</b> lauréats dont <b>42</b>&nbsp;% de femmes et <b>58</b>% d’hommes<br/><br/>En 2021 <b>2</b> nouveaux programmes MOPGA ont été lancés, dont un entièrement dédié à One Health qui oriente les politiques publiques en matière de prévention ou d’innovation en santé.',
        },
        {
          icon: 'b03-phc.png',
          text: '<b>Les Partenariats Hubert Curien (PHC)</b><br/><br/>L’objectif des PHC est de développer les échanges scientifiques et technologiques d’excellence entre les laboratoires de recherche de deux communautés scientifiques, française et étrangère. La gestion des PHC s’est adaptée à l’évolution de la pandémie, bénéficiant pleinement sur le second semestre de la reprise des mobilités de courte et moyenne durée. Un nouveau partenariat PHC, Tonlé Sap, a vu le jour cette année avec le Cambodge.',
        },
      ],
      programsImg: 'b03-mopga.png',
      programsImgCaption:
        'Séminaire de travail MOPGA, en présence du Président de la République, 2019. © Présidence de la République',
      coffeeImg: 'b03-coffee.png',
      coffeeText:
        'Ces sessions d’échanges entre les représentants d’établissements français et l’ambassadeur d’un pays, qui présentent le panorama économique et géopolitique du pays et les enjeux de coopération avec la France rencontrent un vif succès&nbsp;:',
      coffeeBtn: 'Voir la carte',
      popupTrigger: 'popupCafeTrigger',
    },
    b04avril: {
      mainTitle: 'LE SOUTIEN AUX ESPACES',
      subtitle:
        'Les Espaces et antennes Campus France promeuvent l’enseignement supérieur français et la recherche auprès des étudiants des pays dans lesquels ils sont implantés.',
      doctorateIcon: 'b04-promotion.svg',
      doctorateTitle: 'LES CONTRIBUTIONS',
      doctorateText: [
        'Considérant la situation sanitaire, Campus France a autorisé le report global de 65&nbsp;% contributions de 2020 inutilisées. Par ailleurs, 19&nbsp;000 € ont été débloqués en 2021 pour développer l’activité de huit pays prioritaires répartis dans trois zones&nbsp;: Amérique latine, ANMO et Afrique.',
        'Une dotation matérielle (documentation et goodies) a été envoyée aux Espaces, à partir de mars.',
      ],
      commissionIcon: 'b04-commission.svg',
      commissionTitle: 'LA FORMATION RENFORCÉE',
      commissionText: [
        'L’Agence propose désormais aux Espaces (250 agents en 2021) un parcours de formation en ligne.',
        'En 2021, c’est une édition 100 &nbsp;% virtuelle qui a été proposée aux Responsables d’Espaces &nbsp;:',
        '— Janvier &nbsp;: un cycle d’information sur les nouveautés de l’enseignement supérieur suivi par 194 personnes',
        '— Modules de formation suivis par 55 responsables',
        '— Mars &nbsp;: série de webinaires',
      ],
      asideTitle: 'TYPOLOGIE DU PERSONNEL DES ESPACES CAMPUS FRANCE 2021',
      asideText: [
        '483 ETP (équivalents temps plein) du MEAE',
        '71&nbsp;% sont des femmes',
        '65&nbsp;% ont suivi une formation dispensée par l’Agence',
        '74&nbsp;% ont une formation de niveau bac +3 ou plus',
        '76&nbsp;% ont deux ans d’ancienneté ou plus',
      ],
      asideClass: 'aside',
      illustration: 'b04-campusfrancemozambique.jpg',
      leftText: [
        '<b>DES ACTIONS PAR PAYS ET À L’ÉCHELLE RÉGIONALES</b>',
        'Au Brésil, plus de 50 personnes ont pu participer, fin septembre, à deux demi-journées de travail pour renforcer les connaissances et les compétences des agents. Un séminaire régional attractivité Europe, organisé à Budapest, a réuni une trentaine d’agents (COCAC, attachés, responsables d’Espace) venus de 22 pays.',
        'Campus France est également intervenu au séminaire des nouveaux partants dans le réseau.',
      ],
      rightText: [
        '<b>LA GÉNÉRALISATION DU DISPOSITIF ÉTUDES EN FRANCE</b>',
        'En 2021, une quinzaine d’Espaces Campus France a rejoint ce dispositif, portant le total à 65 pays connectés. Campus France intervient sur le volet communication.',
        '<b>UNE MEILLEURE COMMUNICATION INTERNE</b>',
        'D’une newsletter mensuelle au groupe Facebook dédié, les Espaces s’échangent des bonnes pratiques.',
      ],
      boxedImg1: 'b04-alumni.png',
      boxedImg2: 'b04-ambassadors.jpg',
      boxedImg3: 'b04-meetings.png',
      boxedTitle: 'QUELQUES ÉVÈNEMENTS PHARES&nbsp;:',
      boxedSubtitle1: 'Les ambassadeurs France Alumni Inde, avril',
      boxedText1:
        'À l’occasion de la visite en Inde en avril du Ministre de l’Europe et des Affaires étrangères, une rencontre avec des alumni ambassadeurs a été organisée à Delhi. Le réseau France Alumni Inde compte plus de 10&nbsp;000 membres dont certains membres du programme Young Leaders.',
      boxedSubtitle2: 'Les Rencontres France Alumni Palestine, décembre',
      boxedText2:
        'Cette rencontre à Ramallah le 1er décembre, a été l’occasion de réunir des représentants des institutions françaises en Palestine, des alumni, et des directeurs d’établissements.',
      boxedBtn: 'POUR EN SAVOIR PLUS',
      numbersTitle: 'EN CHIFFRES',
      number1: '342&nbsp;000',
      number1Text: 'membres',
      number2: '3&nbsp;500',
      number2Text: 'partenaires dont 1&nbsp;500<br/>entreprises dans 125 pays',
      number3: '286',
      number3Text: 'événements',
    },
    b05mai: {
      title: 'LES BOURSIERS DES GOUVERNEMENTS ÉTRANGERS&nbsp;: UN SUIVI SUR-MESURE',
      subtitle:
        'L’offre de programmes de bourses de Campus France s’est progressivement étoffée, en développant des programmes sur-mesure puis en inscrivant tous les programmes existants ou nouvellement créés dans une démarche qualité.',
      partnerTitle: 'LES PARTENARIATS',
      partnerContent: [
        '<span className="dash">—</span> Renouvellement de l’accord-cadre sur la coopération internationale avec la Higher Education Autority, Irlande sur les arts culinaires, l’hôtellerie et le tourisme.',
        '<span className="dash">—</span> Les programmes de coopération Mexfitec et Mexprotec pour des bourses à des étudiants mexicains',
        '<span className="dash">—</span> Signature du nouveau programme de bourses de l’Entente Cordiale avec le Franco British Council',
        '<span className="dash">—</span> Convention sur la mobilité ivoirienne signée avec le Professeur Adama Diawara, Ministre de l’enseignement supérieur et de la Recherche Scientifique en Côte d’Ivoire',
        '<span className="dash">—</span> 4 accords de coopérations lors 1er Forum universitaire franco-ouzbek à Tachkent et à Samarcande',
        '<span className="dash">—</span> Visite d’études d’une délégation de 8 universitaires malgaches sur des sujets d’entrepreunariat',
        '<span className="dash">—</span> Signature de la nouvelle convention de partenariat avec la HEC pakistanaise',
      ],
      auditionTitle: 'LES AUDITIONS PARLEMENTAIRES',
      auditionContent: [
        'Campus France est devenue un partenaire privilégié du Sénat et de l’Assemblée nationale, consulté sur l’action extérieure de la France, la diplomatie culturelle et l’aide au développement.',
        'En 2021, Campus France a été auditionnée sur des sujets variés tels que les programmes 185 et 209 dans le cadre du Projet de Loi de Finances, la contribution du Quai d’Orsay à l’attractivité de la France, la politique de la France et de l’Union européenne à l’égard de la Chine ou encore le logement et la précarité étudiante.',
      ],
      programTitle: '3 PROGRAMMES PHARES',
      programImage: 'b05-illustration.png',
      malaisieTitle: 'MALAISIE',
      malaisieText:
        'Ce programme a permis d’accueillir une nouvelle promotion de près de 70 étudiants. Coordonné par Campus France avec plus de 30 établissements d’enseignement supérieur, cet accompagnement sur mesure conduit les étudiants à un titre d’ingénieur et un diplôme de master. En 2021, près de 230 boursiers malaisiens en ont bénéficié.',
      malaisieImage: 'b05-illustration.png',
      alulaTitle: 'AL-ULA',
      alulaText:
        'Campus France, l’interlocuteur contractuel unique de la Royal Commission for Al-Ula (RCU), est en charge de la sélection des centres de FLE et du placement à l’université, de l’accompagnement et du suivi des étudiants, mais aussi de leur placement en stage et de la formation en entreprise. Le programme Al-Ula concerne actuellement deux cohortes d’étudiants (29 et 30 étudiants).',
      alulaImage: 'b10-alula-image.png',
      bidTitle: 'BID (ISDB-FRANCE)',
      bidText:
        'Le MEAE et la Banque islamique de développement (BID) poursuivent un programme ambitieux de bourses d’excellence, gérées par Campus France, pour les étudiants de 42 pays, avec un objectif annuel de 200 bourses. En 2021, une présélection de 164 étudiants a été effectuée et 27 étudiants en provenance de 12 pays ont rejoint la France.',
    },
    b06juin: {
      topTitle: 'LES PROJETS EUROPÉENS&nbsp;: LES TEMPS FORTS',
      topSubtitle:
        'Le programme de bourses d’excellence Eiffel permet d’attirer les meilleurs étudiants internationaux, en France, en master et doctorat. En 2021, ce sont 347 candidats qui ont été retenus sur 1&nbsp;478 dossiers évalués.',
      interregIcon: 'b06-interreg.png',
      interregTitle: 'LE PROJET ELAN-INTERREG CARAÏBES',
      interregText:
        'L’Agence a géré les bourses de 70&nbsp;étudiants caribéens en mobilité à l’Université des Antilles en 2021-22. 21&nbsp;étudiants ont passé leur certification FLE. En novembre, Campus France a organisé la 2e&nbsp;édition du salon virtuel ELAN. Cette opération a réuni 55&nbsp;exposants et enregistré plus de 1&nbsp;000&nbsp;connexions. En décembre, la Directrice générale de Campus France s’est déplacée à Sainte-Lucie et à la Martinique.',
      interregImage: 'b06-landscape.png',
      studyTitle: 'LE PROGRAMME STUDY IN EUROPE',
      studyText:
        'Campus France a piloté la nouvelle phase du projet Study in Europe, intitulée <em>Enhancing the attractiveness of European Higher Education in the world</em>, afin d’accompagner les délégations de l’UE dans leurs actions de promotion de l’Europe comme destination d’études.',
      studyIcon: 'b06-studyLogo.png',
      studyImage: 'b06-study.png',
      hopesIcon: 'b06-hopes.png',
      hopesTitle: 'PROJET HOPES-LEB',
      hopesText: [
        'Campus France a lancé un réseau régional d’alumni des programmes financés par le Fonds Madad en Égypte, Jordanie, Kurdistan d’Irak, Liban et Turquie. Des activités de  «&nbsp;réseautage &nbsp;», des opportunités d’études et d’emploi ont été proposées.',
        'En partenariat avec la German Jordanian University et l’ONG néerlandaise SPARK, un événement virtuel a été organisé en novembre pour présenter les résultats des projets et rappeler l’importance du soutien financier apporté par l’UE aux pays en situation d’urgence.',
      ],
      eapIcon: 'b06-eap.png',
      eapTitle: 'LE PROGRAMME EU4DIALOGUE',
      eapText:
        'Campus France a été sélectionné pour mettre en œuvre le volet Éducation et culture du programme EU4Dialogue, financé par l’UE à hauteur de 3 M€ et visant la transformation des conflits non résolus dans quatre pays du Partenariat oriental&nbsp;: Arménie, Azerbaïdjan, Géorgie et Moldavie. Campus France est chargé de la mise en place d’un projet pilote de mobilités vers l’UE, de l’organisation d’une visite d’études dans l’UE, de missions d’enseignants&nbsp;/ experts européens dans la région ou encore du développement de réseaux.',
      meetingTitle: 'LA RÉUNION <em>INTERNATIONAL EDUCATION LEADERSHIP</em>',
      meetingText:
        'Lors de cet événement virtuel, organisé par l’Institute of International Education (IIE), les directeurs des agences de mobilité des pays du G7, de la Finlande, de la Norvège et des Pays-Bas, ont échangé sur les objectifs stratégiques en matière d’internationalisation de l’enseignement supérieur, ainsi que sur le développement durable, l’inclusion et le renforcement des démocraties. Un 2e Sommet, en octobre, a donné lieu à la publication d’une déclaration conjointe.',
      meetingImage: 'b06-meeting.png',
      webinarTitle: 'LE WEBINAIRE RÉGIONAL BELGIQUE/ FRANCE/PAYS-BAS SUR LA RECHERCHE EN SANTÉ GLOBALE',
      webinarText:
        'Organisé en juin par l’Ambassade de France en Belgique, l’Institut français des Pays-Bas et Campus France, en partenariat avec le Service public Économie-emploi recherche de Wallonie, ce webinaire régional a attiré 150 participants.',
      seminarTitle: 'LE SÉMINAIRE RÉGIONAL VIRTUEL FRANCE/PARTENARIAT ORIENTAL',
      seminarText:
        'Mené grâce au dispositif Erasmus+, ce séminaire, organisé en avril, a réuni les cinq pays du Partenariat Oriental (Azerbaïdjan, Biélorussie, Géorgie, Moldavie et Ukraine), près de 250 établissements de ces pays et 33 établissements français pour évoquer la coopération entre ces différents pays.',
      universityTitle: 'C’EST AUSSI LE MOIS DE PRÉPARATION DE LA RENTRÉE UNIVERSITAIRE !',
      topEndTitle: 'C’EST AUSSI LE MOIS DE PRÉPARATION DE LA RENTRÉE UNIVERSITAIRE !',
      bottomTitle: 'WEBINAIRES DE RENTRÉE',
      bottomLeftContent: [
        '<b>Jeudis de l’Accueil</b>',
        'Avec 116 établissements et 280 participants par session, ces webinaires ont fait le bilan d’une année universitaire placée sous le signe de la pandémie et les perspectives de rentrée, mais aussi la dématérialisation des titres de séjour.',
      ],
      bottomRightContent: [
        '<b>Portes ouvertes virtuelles spéciales boursiers</b>',
        'Les 14 et 16 septembre, plus de 200 étudiants boursiers internationaux ont pu prendre connaissance des démarches et des dispositifs mis en place par Campus France pour les aider.',
      ],
      bottomRightImage: 'b06-webinar.png',
    },
    b07juillet: {
      topTitle: 'L’AIDE AUX ÉTUDIANTS LIBANAIS PROLONGÉE',
      topSubtitle:
        'Avec plus de 8&nbsp;000 étudiants libanais en France en 2020- 2021, en augmentation de 40 % sur cinq ans, la France est le 1er pays d’accueil des étudiants libanais. 68 % d’entre eux étudient dans les universités et principalement en sciences. 45 % sont étudiants en master, 35 % en doctorat et 20 % en licence',
      illustration1: 'b07-welcomeCocktail.png',
      illustration2: 'b07-partner.png',
      actionsTitle: 'LES ACTIONS DE PROMOTION ET LES ACTIONS LOCALES',
      actionsText:
        'En 2020- 2021, l’Espace Campus France de Beyrouth et ses huit antennes au Liban ont bénéficié d’une dotation documentaire et financière (4&nbsp;000&nbsp;€). Le SCAC a poursuivi la mise en place de programme de bourses cofinancées avec les établissements d’enseignement supérieur français et a doublé l’enveloppe de bourses du Gouvernement français.',
      maakumTitle: 'MAA’KUM',
      maakumText:
        '(Avec Nous) est un programme d’aide à l’installation des étudiants libanais nouvellement arrivés en France. Financé par le ministère de l’Europe et des Affaires étrangères, mis en œuvre par Campus France, il a bénéficié à plus de 2&nbsp;500 étudiants libanais primo-arrivants en 2020-21.',
      partnerTitle: 'LE PARTENARIAT HUBERT-CURIEN CEDRE',
      partnerText:
        'Financé par la France (215&nbsp;000€ en 2021), il a pour objectif de développer des échanges scientifiques et technologiques d’excellence entre laboratoires des deux pays.',
      partnerImage: 'b11-phc-cedre.jpg',
      scholarshipTitle: 'LES BOURSES COUP DE POUCE',
      scholarshipText:
        '25 bourses ont été allouées à la rentrée 2021, pour accompagner les étudiants libanais à leur arrivée en France.',
      elyseeTitle: 'LE PROGRAMME ÉLYSÉE - VOLET LIBAN',
      elyseeText:
        'Une dizaine de boursiers ont été accueillis en 2021. Au total, 54 boursiers syriens ont été accompagnés par le Poste diplomatique depuis la création de ce programme.',
      hopesTitle: 'LE PROGRAMME EUROPÉEN HOPES-LEB',
      hopesText:
        'Depuis 2020, Campus France continue à accompagner l’Institut français du Liban dans ses efforts de promotion.',
      webinarTitle: 'LE WEBINAIRE D’ACCUEIL DES ÉTUDIANTS LIBANAIS',
      webinarText:
        'Auprès de 300 étudiants libanais a été organisé par Campus France. La Cité internationale universitaire de Paris a accueilli une soixantaine d’entre eux le 6&nbsp;octobre à la Maison du Liban.',
      bottomTitle: 'EN DEHORS DU LIBAN, LA FRANCE VIENT EN AIDE À :',
      bottomLeftContent: [
        '<b>Afghanistan</b>',
        'Suite à la chute de Kaboul aux mains des Talibans le 15&nbsp;août&nbsp;2021, l’Ambassade de France, Campus France, et les Conférences d’établissements se sont organisés pour accueillir des étudiants à la rentrée. Les établissements français accueillent environ 450 étudiants afghans par an.',
      ],
      bottomRightContent: [
        '<b>Haïti</b>',
        'Haïti a connu plusieurs catastrophes naturelles venant aggraver une situation économique difficile. La France étant le 1er&nbsp;pays d’accueil avec 4 000 étudiants haïtiens, l’Espace Campus France a vu les demandes tripler en quelques années. L’effort a porté sur les bourses, dans le cadre de partenariats avec la BRH (Banque de la République d’Haïti), et la Sogebank. 16 étudiants haïtiens ont été retenus au sein du programme ELAN.',
      ],
      bottomImage: 'b07-illustration.png',
    },
    b08aout: {
      title: 'LES PUBLICATIONS',
      parutionTitle: '2021 a été une année riche en parutions.',
      parutionContent: [
        '<span class="color"><b><a href="https://www.campusfrance.org/fr/ressource/chiffres-cles-2021" target="_blank">Les Chiffres clés 2021</a></b></span> analysent les données de la plateforme Études en France, montrant les effets de la pandémie sur la mobilité.',
        'Les stratégies différenciées des pays d’accueil ont été étudiées dans les <span class="color"><b>Brèves</b></span>.',
        'Une <span class="color"><b>synthèse des indicateurs</b></span> de la mobilité, des partenariats et de la coopération a été réalisée pour l’Amérique centrale, Israël, la Pologne, l’Ouzbékistan, le Japon et la Corée du Sud.',
        '<span class="color"><b><a href="https://www.campusfrance.org/fr/recherche/categorie/fiches-mobilite-pays-943" target="_blank">150 fiches mobilité</a></b></span> répertorient les chiffres clés par pays. Quatre <span class="color"><b>fiches statistiques boursiers</b></span> complètent le suivi des BGF, depuis l’attribution des bourses jusqu’à la réussite dans les études.',
      ],
      parutionImage1: 'b08-kpis.png',
      parutionUrlImage1: 'https://www.campusfrance.org/fr/ressource/chiffres-cles-2021',
      parutionImage2: 'b08-morocco.png',
      parutionUrlImage2: 'https://www.campusfrance.org/fr/recherche/categorie/fiches-mobilite-pays-943',
      occasionTitle:
        '<a href="https://www.campusfrance.org/fr/les-10-ans-de-campus-france-les-temps-forts#:~:text=En%202021,%20Campus%20France,%20l,internationalisation%20de%20l&apos;enseignement%20sup%C3%A9rieur" target="_blank">À l’occasion de ses 10 ans</a>, Campus France a fait paraître 2 publications inédites&nbsp;:',
      occasionContent: [
        '« <b>Campus France&nbsp;: 10 ans d’attractivité »</b> retrace la décennie d’existence de l’Agence et « <b>En chiffres&nbsp;: 10 ans de mobilité</b> » chiffre l’évolution des tendances mondiales de la mobilité étudiante.',
      ],
      occasionImage1: 'b08-attractivity.png',
      occasionUrlImage1:
        'https://www.campusfrance.org/fr/campus-france-10-ans-d-attractivite#:~:text=Contrairement%20%C3%A0%20certains%20grands%20pays,rapport%20%C3%A0%20la%20rentr%C3%A9e%202019)',
      occasionImage2: 'b08-mobility.png',
      occasionUrlImage2: 'https://www.campusfrance.org/fr/ressource/campus-france-10-ans-de-mobilite-en-chiffres',
      forumTitle: 'LE FORUM CAMPUS FRANCE&nbsp;: UNE ANNÉE D’ÉCHANGES',
      forumSubTitle: [
        '<b>Le Forum Campus France regroupe les conférences d’établissements. Il est présidé par Emmanuelle Garnier, présidente de l’Université Toulouse Jean-Jaurès, depuis février 2021. Les 371 établissements mobilisés ont pu partager leurs bonnes pratiques en matière d’internationalisation.</b>',
        '',
        '<u><b>Un rythme soutenu</b></u>',
        'Le Bureau et les Commissions ont organisé une dizaine de réunions en ligne et de webinaires thématiques.',
      ],
      leftContent: [
        '— <b>23 mars</b>&nbsp;: webinaire des Commissions, en présence de 260 participants, et de Cyril Piquemal, Directeur adjoint de l’Union Européenne au MEAE et de Slim Khalbous, Recteur de l’AUF.',
        '',
        '— <b>28 juin</b>&nbsp;: webinaire de la Commission Afrique sur l’enseignement numérique, avec 140 participants dont la Cheffe adjointe de l’Unité H2 (Asie, Afrique, ANMO) de la Commission européenne, du Chef de projet Campus connecté pour le MESRI et du Coordonnateur de l’Université virtuelle du Sénégal',
      ],
      rightContent: [
        '— <b>29 juin</b>&nbsp;: webinaire de la Commission Europe sur l’attractivité des étudiants européens, avec 160 participants, dont Laure Coudret-Laut, Directrice de l’Agence Erasmus + et de représentants écossais et irlandais',
        '',
        '— <b>9 septembre</b>&nbsp;: webinaire de la Commission Recherche avec 1000 participants dont l’ancien Coordinateur du programme d’enseignement supérieur de la Banque mondiale, de la Chargée de Stratégies RH/recherche du MESRI. Ces échanges ont porté sur le renforcement de l’attractivité du doctorat français et les avancées que permet la loi de programmation de la Recherche (article 12).',
      ],
      bottomImage: 'b08-presidente.jpg',
    },
    b09septembre: {
      icon: 'b09-book-icon.svg',
      franceTitle: 'BIENVENUE EN FRANCE',
      francePoliticTitle: 'UNE POLITIQUE VOLONTARISTE D’OUVERTURE',
      francePoliticContent: [
        'La stratégie Bienvenue en France a fixé un cap ambitieux&nbsp;: accueillir 500 000 étudiants étrangers en 2027.',
        'Un comité de pilotage bimensuel fait le lien avec les établissements pour coordonner la politique d’accueil des étudiants internationaux, et a favorisé&nbsp;:',
        '<span className="dash">—</span> l’instruction du Premier ministre confirmant le maintien des frontières ouvertes aux étudiants internationaux&nbsp;;',
        '<span className="dash">—</span> la circulaire d’août 2021 confirmant l’extension du motif impérieux pour les étudiants non vaccinés des  «&nbsp;zones rouges et oranges&nbsp;»&nbsp;;',
        '<span className="dash">—</span> l’accueil tardif des étudiants internationaux&nbsp;;',
        '<span className="dash">—</span> le soutien aux étudiants en difficulté',
      ],
      franceRentreeTitle: 'LA RENTRÉE 2021&nbsp;: RETOUR À L’AVANT-CRISE',
      franceRentreeContent: [
        'Grâce à une vaccination massive (plus de 80&nbsp;% des 18-25 ans), la France, bien placée face à ses concurrents avait entièrement résorbé les effets de la crise en octobre 2021, avec près de 80 000 visas délivrés, 25&nbsp;% de plus qu’à la rentrée 2020, et 3&nbsp;% de plus qu’en 2019.',
        'Un partenariat avec la CDEFM a été signé à la rentrée en faveur d’actions de promotion de la formation en management.',
      ],
      franceRentreeImage: 'b09-perspectives.png',
      statsTitle: 'LES CHIFFRES DE L’INTERNATIONALISATION EN FRANCE EN 2020-2021',
      statsStudentTitle: 'LES ÉTUDIANTS INTERNATIONAUX',
      statsStudentContent: [
        { title: '14&nbsp;%', desc: "des étudiants en université, 2 fois plus qu'en 1998-1999" },
        { title: '20&nbsp;%', desc: "en écoles de commerce, 10 fois plus qu'en 1998" },
        { title: '15&nbsp;%', desc: "en écoles d'ingénieurs, 6 fois plus qu'en 1998" },
        {
          title: '6&nbsp;%',
          desc: "hébergés dans d'autres établissements, notamment en lycées, comme les CPGE ou les BTS, +60% depuis 1998",
        },
      ],
      statsDoctorTitle: 'CHERCHEURS / DOCTORANTS INTERNATIONAUX',
      statsDoctorContent: [
        { title: '41&nbsp;%', desc: 'des doctorants en 2020, vs 27&nbsp;% en 1999-2000' },
        { title: '7,5&nbsp;%', desc: "des effectifs d'enseignants-chercheurs, +8% depuis 1999" },
      ],
      moreInfoLeftTitle: 'LE DÉPLOIEMENT DES GUICHETS D’ACCUEIL UNIQUES',
      moreInfoLeftContent:
        'Dans plus de 40 villes, les étudiants internationaux bénéficient des services d’un bureau d’accueil à leur arrivée qui regroupe tous les interlocuteurs utiles.',
      moreInfoRightTitle: 'LES CHANTIERS PRIORITAIRES POUR L’AVENIR',
      moreInfoRightContent:
        'Campus France a la volonté de favoriser les mobilités Erasmus, d’augmenter le nombre de cours en anglais, de renforcer le lien avec les régions, de favoriser l’accès au logement, et de renforcer le lien avec Erasmus +.',
      labelTitle: 'Le label',
      labelImage: 'b09-page.png',
      labelImageUrl:
        'https://www.campusfrance.org/fr/label-bienvenue-en-france-un-flyer-d-information-pour-les-etudiants-internationaux',
      labelPatchImage: 'b09-patch.png',
      labelText:
        'Ce label certifie la qualité de l’accueil des étudiants internationaux au sein des établissements. 134 établissements ont été labellisés dont 32 en 2021. Campus France compte un vivier de 73 experts indépendants. La promotion du label a été assurée via la formation des Espaces Campus France au Brésil, le séminaire attractivité Europe, et la formation des nouveaux Responsables d’Espace.',
      labelBtn: 'JE DÉCOUVRE',
      labelBtnUrl: 'https://www.campusfrance.org/fr/le-label-bienvenue-en-france',
    },
    b10octobre: {
      animationImages: [
        'components/month-april.png',
        'components/month-august.png',
        'components/month-december.png',
        'components/month-february.png',
        'components/month-january.png',
        'components/month-july.png',
        'components/month-june.png',
        'components/month-march.png',
        'components/month-may.png',
      ],
      animationOrder: [0, 2, 8, 6, 1, 5, 7, 3, 4],
      animationTiming: 240, // ms
      rdvTitle: 'RENDEZ-VOUS EN FRANCE',
      rdvText:
        'Cette campagne se présente comme une réponse au vif regain du désir de mobilité après deux ans de pandémie. Elle valorise l’idée que l’expérience d’études en France constitue un rendez-vous marquant dans la trajectoire de chacun.',
      rdvTopVideoImage: 'b10-top-video.png',
      rdvTopVideoUrl: 'https://www.youtube.com/watch?v=el-98XdqbTw',
      axeTitle: 'LES AXES',
      axeContent: [
        '- <b>Rendez-vous en France, un enseignement de rang mondial',
        'La France occupe en 2021 la troisième place mondiale au classement de Shanghai.',
        '- <b>Rendez-vous en France, study in English</b>',
        'La France propose plus de 1 600 formations en anglais',
        '- <b>En France, on prend soin de vous</b>',
        'Le gouvernement ainsi que les établissements d’enseignement supérieur facilitent l’accès à la vaccination, et plus largement aux soins en France.',
        '',
        'Un kit de communication adaptable à l’échelle locale est mis à disposition des 275 Espaces et antennes Campus France.',
      ],
      axeButtonText: 'JE DÉCOUVRE',
      axeButtonUrl: 'https://www.campusfrance.org/fr/rendez-vous-en-france ',
      rdvBottomVideoImage: 'b10-bottom-video.png',
      rdvBottomVideoUrl: 'https://www.youtube.com/watch?v=AQZ-5BDUrXw',
      infoTitle: 'L’INFORMATION DES ÉTUDIANTS INTERNATIONAUX&nbsp;: UNE OFFRE ADAPTÉE',
      infoSubtitle: 'LES CATALOGUES DE PROMOTION DE L’OFFRE D’ENSEIGNEMENT',
      infoContent: [
        { title: '7', desc: 'moteurs de recherche, accessibles à partir des 90 sites internet de Campus France' },
        { title: '6&nbsp;500', desc: 'offres et programmes référencés' },
        {
          title: '3&nbsp;031&nbsp;690',
          desc: 'sessions de consultation en 2021, dont plus de 40&nbsp;% pour l’offre de niveau licence et master',
        },
        { title: '1&nbsp;837&nbsp;286', desc: 'utilisateurs' },
        { title: '+&nbsp;5&nbsp;%', desc: 'd’utilisateurs par rapport à la même période en 2020' },
        { title: '+&nbsp;6&nbsp;%', desc: 'de consultations par rapport à la même période en 2020' },
      ],
      whiteBoxText:
        '<span class="blue">L’activité des éditions de promotion en 2021</span> a été soutenue, par la parution entre autres, de guides Choisir la France et AEFE ou encore de nouvelles fiches domaines.',
      whiteBoxButtonText: 'JE DÉCOUVRE',
      whiteBoxButtonUrl: 'https://www.campusfrance.org/fr/espace-documentaire',
      catalogueTitle: 'DEUX CATALOGUES PHARES',
      campusartImage: 'b10-campusart.png',
      campusartTitle: 'LE PROGRAMME CAMPUSART',
      campusarContent:
        'CampusArt est constitué d’un site internet d’information sur les études et diplômes d’art et d’architecture, une plateforme de candidature pour les étudiants ainsi qu’une plateforme de sélection pour le compte des 150 établissements.',
      campusarButtonText: 'JE DÉCOUVRE',
      campusarButtonUrl: 'https://www.campusart.org/',
      formationImage: 'b10-formation.png',
      formationTitle: 'MA FORMATION À DISTANCE',
      formationContent: [
        'En 2021, le catalogue assure désormais la promotion d’une offre de 700 formations à distance.',
        '— 56&nbsp;% des établissements référencés sont publics',
        '— 37&nbsp;% sont des établissements privés et 7&nbsp;% des établissements consulaires',
        '— Les domaines les plus représentés sont&nbsp;: management, gestion, finances et commerce (25&nbsp;%)&nbsp;; informatique et numérique (11&nbsp;%)&nbsp;; sciences de l’ingénieur (10&nbsp;%)&nbsp;; langues et lettres (8&nbsp;%)&nbsp;; sciences humaines et sociales (7&nbsp;%).',
      ],
      formationButtonText: 'JE DÉCOUVRE',
      formationButtonUrl: 'https://foad.campusfrance.org/#/main',
    },
    b11novembre: {
      title: 'LES 10 ANS DE CAMPUS FRANCE',
      videoPreviewImage: 'b11-video-preview.png',
      videoUrl: 'https://www.youtube.com/watch?v=DqRSmiF4a6M&list=PLpm2evF1qaJe-3ECczQ3tCcrB0aFQH60V',
      tenYearImage: 'b11-campus-logo.png',
      tenYearText:
        'En dix ans, Campus France est devenu un acteur incontournable de la mobilité étudiante, au centre d’un écosystème complexe. L’Agence a accompagné la montée en puissance de l’internationalisation de l’ESR français, et en a fait un enjeu indiscutable. Véritable tête de réseau des Espaces Campus France, Campus France a aussi unifié et démultiplié la promotion des études et de la recherche en France. Un rôle d’opérateur central dont la crise sanitaire a montré l’importance et l’efficacité.',
      interviewTitle: 'CAMPUS FRANCE&nbsp;: 10 ANS D’ATTRACTIVITÉ, INTERVIEW DE BÉATRICE KHAIAT À NEWSTANK',
      interviewText:
        '« Le décret de décembre 2011 portant acte de sa création fixait pour l’agence Campus France trois missions principales&nbsp;: la promotion de l’ESR, la gestion des mobilités avec les bourses, missions et invitations, la participation de la France aux programmes européens. Au cours des 10 ans, s’y sont adjointes 3 missions nouvelles&nbsp;: la promotion de la recherche, et du continuum Formation Recherche Innovation, une communication mondiale sur tous les réseaux sociaux, et la production de nombreuses publications et études analysant la mobilité internationale étudiante. »',
      interviewImage: 'b11-beatricekhaiat.jpg',
      partnersTitle: 'LES PARTENAIRES EUROPÉENS DE L’AGENCE',
      partnersIcon1: 'b11-iie.png',
      partnersText1:
        '« We send enthusiastic greetings to the dedicated leadership and staff of Campus France for all the important work you do every day in the promotion of higher education and international mobility to the great benefit of students and researchers from around the globe ».',
      partnersIcon2: 'b11-nuffic.jpg',
      partnersText2:
        '« In our cooperation Campus France every time proves to be a valuable, knowledgeable and professional partner »',
      partnersIcon3: 'b11-daad.svg',
      partnersText3:
        '« Campus France and the DAAD, have been implementing the EU project ‘Enhancing the attractiveness of European Higher Education in the world: Study in Europe’, which has been coordinated by Campus France since 2017 »',
      partnersIcon4: 'b11-britishcouncil.png',
      partnersText4:
        '« We value highly our unique relationship with Campus France and look forward to a future building on our work and breaking new ground in years to come »',
      meetTitle: 'LES RENCONTRES CAMPUS FRANCE',
      meetLeftText:
        'L’édition 2021 des Rencontres, placée sous le symbolique anniversaire des 10 ans, a été un moment privilégié pour dresser un bilan et pour réfléchir aux défis pour l’attractivité de l’enseignement supérieur français. Du 15 au 17 novembre, en format hybride, les Rencontres ont été ouvertes par la ministre de l’Enseignement supérieur, de la Recherche et de l’Innovation&nbsp;: « Campus France s’est imposée comme la cheville ouvrière du rayonnement international de nos établissements, comme l’interlocuteur privilégié des ministères, des établissements, des postes diplomatiques ainsi que des étudiants et des chercheurs à l’étranger ».',
      meetRightText: [
        '<u><b>Des ateliers thématiques</b></u>',
        'Près de 1 500 personnes, parmi lesquelles 104 Postes diplomatiques et 204 établissements ont été associés aux Rencontres. Plus de 1&nbsp;000 rendez-vous et 7&nbsp;200 connexions ont été réalisées en parallèle des 46 ateliers thématiques.',
        '120 experts ont apporté un éclairage précieux sur des thématiques stratégiques&nbsp;: l’accent a été mis sur la zone Indopacifique et l’espace européen, à la veille de la PFUE. D’autres ateliers ont porté sur les leviers d’attractivité qui permettront de consolider la place de la France parmi les grands pays d’accueil.',
      ],
      meetImage: 'b11-meetings.jpg',
    },
    b12decembre: {
      title: 'LA PROMOTION DE LA FRANCE EN EUROPE',
      tableauBluePartText: '<b>UN TABLEAU CONTRASTÉ DE L’ATTRACTIVITÉ FRANÇAISE</b>',
      tableauBluePartText1: [
        '<b>EUROPE (UE ET HORS UE)&nbsp;:</b>',
        '- 1re zone d’accueil des étudiants mobiles',
        '- 2e zone d’origine',
      ],
      tableauBluePartImageText1: 'b12-europa.svg',
      tableauBluePartText2: [
        '<b>FRANCE&nbsp;:</b>',
        '- 5e pays d’accueil de la mobilité de l’UE',
        '- 14 % des étudiants accueillis sont de l’UE',
      ],
      tableauBluePartImageText2: 'b12-france.svg',
      tableauBluePartImage: 'b12-attractivity.png',
      seminaireRedPartText:
        '<span><b>LE SÉMINAIRE ATTRACTIVITÉ EUROPE À BUDAPEST</b></span><br><span>Campus France a organisé les 27 et 28 septembre un séminaire de travail à Budapest, avec 30 participants de 23 postes diplomatiques afin de renforcer l’attractivité française en Europe.</span><br> <span><b>Un plan d’action</b></span><br> <span>La PFUE constitue une opportunité pour renforcer la visibilité de la France par le biais de programmes européens. Le plan d’action incite les postes à se saisir de la stratégie européenne du MEAE, à dynamiser leur réseaux d’alumni ou encore à valoriser les programmes de bourses et les doubles diplômes.</span><br> <span>Trois pistes de réflexion ont également été lancées pour évoquer une campagne de communication spécifique à l’UE, mais aussi la valorisation des mobilités virtuelles et la création d’un programme de bourses central pour les Postes européens.</span>',
      seminaireRedPartImage: 'b12-budapest.png',
      soutienBluePartText:
        '<span><b>UN SOUTIEN ACCRU AUX ESPACES EN EUROPE</b></span><br><span>L’Agence a assuré une large communication sur de grands salons internationaux de l’éducation en Europe comme en en République tchèque (novembre) et a soutenu la participation des Espaces comme le salon Éducatio en Hongrie (janvier).</span><br><span>Campus France est également partenaire du réseau ESN Networks, qui regroupe des associations dans les villes universitaires européennes (50 en Europe et 90 en France).</span>',
      soutienImage: 'b12-COCAC.png',
      diplomatieRedPartContent: [
        '<b>DIPLOMATIE D’INFLUENCE</b><br><span>Le ministre de l’Europe et des Affaires étrangères a présenté le 14 décembre, lors des Journées du réseau des COCAC, la feuille de route de l’influence de la diplomatie française.</span><br><span><b>Des priorités stratégiques</b></span><br><span>Parmi les priorités, la volonté de mettre en place « enseignement d’excellence du français au service du plurilinguisme et de la &nbsp; jeunesse » -avec notamment le doublement des effectifs des écoles françaises à l’étranger d’ici 2030 - , d’attirer les talents et d’accompagner la projection internationale des établissements.</span><br><span><b>Attirer de nouveaux talents en 3 points :</b></span><br><span>1. améliorer les conditions d’accueil des étudiants, en attirant davantage d’étudiants asiatiques&nbsp;;</span><br><span>2. développer les campus franco-étrangers, notamment dans l’Indopacifique, et multiplier les processus de co-diplomation;</span><br><span>3. s’appuyer sur les alliances d’universités européennes<br/><br/>Au-delà de ces trois points, une gamme de dispositifs est envisagée.</span>',
      ],
      diplomatieRedPartButtonText: 'POUR EN SAVOIR PLUS',
      diplomatieRedPartButtonUrl:
        'https://www.campusfrance.org/fr/une-nouvelle-strategie-d-influence-pour-l-enseignement-superieur-et-la-recherche-francais',
      conseilBluePartText:
        '<b>LE CONSEIL D’ORIENTATION DE CAMPUS FRANCE</b><br/><br/>La réunion annuelle a dressé le bilan de dix ans d’actions en faveur de l’accueil des étudiants et des chercheurs internationaux et tracé des perspectives pour l’avenir. Présidé par Jean Peeters, le Conseil d’orientation a exprimé plusieurs recommandations:',
      conseilBluePartButtonText: 'POUR EN SAVOIR PLUS',
      conseilBluePartButtonUrl:
        'https://www.campusfrance.org/fr/conseil-d-orientation-de-campus-france-retour-sur-10-ans-d-actions',
      conseilVideoPreviewImage: 'b12-video.png',
      conseilVideoUrl: 'https://youtu.be/JhNJWkJ7hYI',
      signatureRedPartText:
        '<b>SIGNATURE D’UNE CONVENTION AVEC LE HCERES</b><br/><br/>L’accord identifie six axes de coopération, parmi lesquels des actions communes pour la visibilité de l’ESR français, l’identification d’experts universitaires, l’articulation entre l’évaluation des établissements et l’attribution du Label Bienvenue en France.',
      signatureRedPartImage: 'b12-thierry-coulhon.png',
    },
  },
  components: {
    months: {
      january: {
        bg: 'month-january.png',
        textTop: 'JAN',
        textBottom: 'VIER',
        desc: 'L’inventivité et l’agilité sont deux des atouts des établissements d’enseignement supérieur français que Campus France a su promouvoir et valoriser.',
      },
      february: {
        bg: 'month-february.png',
        textTop: 'FÉV',
        textBottom: 'RIER',
        desc: 'La perception très favorable des boursiers du Gouvernement Français est un outil majeur de la diplomatie d’influence française.',
      },
      march: {
        bg: 'month-march.png',
        textTop: 'MA',
        textBottom: 'RS',
        desc: 'Suite à la promulgation de la Loi de Programmation de la Recherche (LPR), l’Agence accompagne les établissements sur le volet international de cette loi et a poursuivi sans relâche sa mission de promotion de la recherche, sous des formats variés, comme les café-croissants.',
      },
      april: {
        bg: 'month-april.png',
        textTop: 'AV',
        textBottom: 'RIL',
        desc: 'Composé de 275 bureaux implantés dans 134 pays, le réseau des Espaces Campus France est l’un des plus importants au monde. La France rayonne également à travers les ambassadeurs France Alumni.',
      },
      may: {
        bg: 'month-may.png',
        textTop: 'MAI',
        textBottom: '',
        desc: 'Les programmes des boursiers des gouvernements étrangers sont des outils majeurs de la diplomatie d’influence française.',
      },
      june: {
        bg: 'month-june.png',
        textTop: 'JU',
        textBottom: 'IN',
        desc: 'Depuis sa création, Campus France porte et accompagne activement les projets financés par l’Union Européenne et a développé un réseau international de partenaires.',
      },
      july: {
        bg: 'month-july.png',
        textTop: 'JUIL',
        textBottom: 'LET',
        desc: 'Le Liban bénéficie d’une relation privilégiée avec la France, notamment dans le domaine de la coopération dans l’enseignement supérieur et la recherche et l’aide aux étudiants dont Campus France se fait le relais, mais l’Agence vient également en aide à d’autres pays en crise.',
      },
      august: {
        bg: 'month-august.png',
        textTop: 'AO',
        textBottom: 'ÛT',
        desc: 'Afin de développer une stratégie d’attractivité forte à l’international, Campus France développe des analyses chiffrées et réfléchit en commun au sein du FORUM.',
      },
      september: {
        bg: 'month-september.png',
        textTop: 'SEPT',
        textBottom: 'EMBRE',
        desc: 'En 2021, la mise en œuvre de la stratégie Bienvenue en France et une adaptation concertée entre les acteurs publics face aux évolutions de la situation sanitaire ont permis d’accueillir les étudiants internationaux à la rentrée.',
      },
      october: {
        bg: 'month-october.png',
        textTop: 'OCT',
        textBottom: 'OBRE',
        desc: 'La stratégie Bienvenue en France s’enrichit cette année d’une nouvelle campagne de communication, qui donne  «&nbsp;Rendez-vous en France &nbsp;» aux étudiants du monde entier.',
      },
      november: {
        bg: 'month-november.png',
        textTop: 'NOV',
        textBottom: 'EMBRE',
        desc: 'Campus France célèbre ses dix ans d’existence au service de l’attractivité et l’internationalisation de l’enseignement supérieur français. Le temps de s’autoriser un regard en arrière et un pas en avant.',
      },
      december: {
        bg: 'month-december.png',
        textTop: 'DÉC',
        textBottom: 'EMBRE',
        desc: 'À la veille de la PFUE, Campus France participe à  «&nbsp;l’Europe de la coopération &nbsp;», dans ses relations et programmes avec ses homologues européens mais aussi à travers les manifestations de promotion en Europe. La fin d’année est également l’occasion de dessiner des priorités stratégiques de l’enseignement supérieur.',
      },
    },
  },
  popups: {
    ceo: {
      author: 'Béatrice Khaiat',
      role: 'DIRECTRICE GÉNÉRALE',
      imgSrc: 'ceo1.png',
      quote:
        '«&nbsp;Cet impératif d’adaptation a naturellement été la ligne directrice des efforts que nous avons mis en œuvre pour préparer la rentrée 2020-2021. Car il était proprement impensable de sacrifier à la crise les ambitions que nous portons en matière d’attractivité universitaire. Les enjeux sont trop importants&nbsp;».',
      quoteCaption: 'Extrait du discours de Jean-Yves Le Drian aux Rencontres Campus France, 18 novembre 2020',
      desc1:
        '<b>C’est cette ambition en matière d’attractivité que nous avons portée tout au long de cette année, une année paradoxale, partagée entre espoir, optimisme et attentisme.</b><br/>Le maître mot de cette année 2021 aura été celui de l’adaptation. Adaptation à la crise sanitaire bien sûr et à ses aléas. À la fin du mois d’octobre de cette année, grâce à nos efforts collectifs, la France avait quasiment effacé les effets de la crise, avec près de 80&nbsp;000 visas délivrés, soit 25&nbsp;% de plus qu’à la rentrée 2020, et 3&nbsp;% de plus qu’à la même date en 2019, la dernière rentrée sans Covid.<br/>Plusieurs éléments ont favorisé cette réussite, à commencer par la politique gouvernementale volontariste d’ouverture des frontières aux étudiants étrangers. La vaccination massive des jeunes, et en particulier celles des étudiants, a été un facteur décisif de la reprise, d’autant que les établissements se sont engagés, sous l’impulsion de la ministre chargée de l’enseignement supérieur, à réaliser une rentrée sous la forme présentielle et sans jauge.<br/>Dans ce contexte, la coordination interministérielle, mise en place grâce à la stratégie Bienvenue en France, s’est révélée efficace. Campus France, placé au cœur de cette stratégie, a joué un rôle important de diffusion d’informations auprès des étudiants, via les Espaces Campus France.<br/>Nous avons créé de nouveaux outils, de nouvelles façons d’agir, dans nos actions de promotion dans le monde, dans la gestion des bourses ou dans l’accueil et la gestion des boursiers pour études qui ont bien été au rendez-vous en cette année 2021.',
      desc2:
        'De même, dans nos relations avec les établissements, dans nos efforts pour améliorer l’accueil avec la gestion du Label Bienvenue en France, dans nos outils d’information et nos campagnes de communication, nous avons cherché, avec les Espaces, à mieux atteindre nos cibles. Si toutes ces actions sont souvent passées par le virtuel ou le distanciel, nous avons organisé, dès que cela a été possible, des manifestions en présentiel, comme ce fut le cas notamment en Afrique ou encore en Europe, avec le séminaire sur l’attractivité. Avec la Présidence française de l’Union européenne, nous nous tournons de façon encore plus résolue vers l’Europe pour favoriser la mobilité intra-européenne et l’attractivité de la France. Campus France est un interlocuteur de premier plan, aussi bien pour ses homologues européens que pour les nombreux Espaces. L’année 2021, qui a marqué les dix ans de la création de l’EPIC Campus France, nous a permis de mesurer à quel point l’Agence a été réactive et créative. C’est cette réactivité pendant la crise sanitaire qui devrait nous permettre de regagner des places en termes de compétitivité face à nos concurrents. La France se classe 3e au classement de Shanghai 2021, avec 5 établissements dans le top 100, et 1er pays européen. Comme l’écrit la ministre de l’Enseignement supérieur, de la Recherche et de l’Innovation dans sa préface pour les 10 ans de l’Agence, «&nbsp;Campus France doit être encore et toujours au cœur de notre attractivité, au service des étudiants, en appui de nos établissements&nbsp;».',
    },
    africa: {
      title: "L'Afrique",
      column1: [
        {
          title: 'ATELIER AFRIQUE DU SUD, JUIN',
          text: 'Plus de 40 représentants d’établissements ont participé à cette matinée en ligne.',
        },
        {
          title: 'SALON DE PROMOTION DES ÉTUDES EN FRANCE, TCHAD, OCTOBRE',
          text: 'L’Ambassade de France et Campus France Tchad ont organisé le 1er Salon des études en France à l’Institut Français, qui ont attiré 8&nbsp;000 lycéens et étudiants.',
          image: 'africa1.png',
        },
      ],
      column2: [
        {
          title: 'CHOOSE FRANCE TOUR ÉTHIOPIE-KENYA, OCTOBRE',
          text: '11 établissements français a participé à cette première édition.',
        },
        {
          title: 'SALON VIRTUEL CHOOSE FRANCE-NIGERIA, DÉCEMBRE',
          text: 'Ce salon étudiant virtuel a été organisé par l’Ambassade de France au Nigeria et Campus France, avec 19 établissements français et près de 2&nbsp;500 visiteurs.',
          image: 'africa2.png',
        },
      ],
    },
    america: {
      title: "L'Amérique latine",
      column1: [
        {
          heading:
            'L’Amérique latine a su rapidement s’adapter, en proposant des opérations de promotion virtuelle et en déployant la campagne Bienvenue en France.',
        },
        {
          title: 'TU MAESTRIA EN FRANCIA - TU DOCTORADO EN FRANCIA, MEXIQUE, FÉVRIER ET MARS',
          text: 'Sous format virtuel, le salon a réuni 27 établissements français et près de 1&nbsp;000 étudiants de l’ensemble du territoire mexicain.',
        },
        {
          title: 'JOURNÉES AMÉRIQUE CENTRALE, JUIN',
          text: 'Ces premières rencontres universitaires France-Amérique centrale ont été organisées en ligne dans le cadre de la commémoration des bicentenaires des indépendances de plusieurs pays d’Amérique centrale. Elles ont réuni 24 établissements français et 34 universités centraméricaines de 6 pays différents.',
        },
      ],
      imgSrc1: 'america1.jpg',
      imgSrc2: 'america2.jpg',
    },
    anmo: {
      title: 'L’Afrique du nord et le Moyen-Orient<br/>(ANMO)',
      column1: [
        {
          heading:
            'L’attractivité de la France demeure forte et en progression sur les zones de l’Afrique du nord, du Moyen-Orient et de l’Afrique subsaharienne.',
        },
        {
          title: 'SALON JORDANIE, NOVEMBRE',
          text: '9 établissements français ont pu rencontrer une centaine de lycéens et étudiants et les universités jordaniennes.',
        },
        {
          title: 'DÉPLACEMENT EN ISRAËL, NOVEMBRE',
          text: 'À l’occasion de l’inauguration du nouvel Espace Campus France de Tel-Aviv le 29 novembre, la Direction de Campus France a échangé avec les acteurs du monde universitaire et de la recherche en Israël, et les Alumni du programme de bourses de recherche Chateaubriand.',
          image: 'anmo3.jpg',
        },
      ],
      column2: [
        {
          title: 'SALON CHOOSE FRANCE ÉGYPTE, NOVEMBRE',
          text: '14 établissements français ont pu échanger avec les 1&nbsp;500 étudiants et les établissements égyptiens.',
          image: 'anmo2.jpg',
        },
        {
          title: 'CHOOSE FRANCE ÉMIRATS ARABES UNIS, DÉCEMBRE',
          text: 'Deux salons étudiants à la Sorbonne Abu Dhabi et à l’Alliance Française de Dubaï ont été organisés pour cette 6e tournée du Golfe. Au pavillon France de l’Exposition Universelle de Dubaï 2021, une conférence sur l’enseignement supérieur et l’innovation a réuni 14 établissements français, des alumni et des étudiants émiratis.',
          image: 'anmo1.jpg',
        },
      ],
    },
    asia: {
      title: 'L’Asie',
      column1: [
        {
          heading:
            'Bien que 16 événements virtuels aient été organisés, les mobilités restent fortement impactées par le choix de certains pays pour une stratégie zéro Covid et la fermeture des frontières.',
        },
        {
          title: 'CHOOSE FRANCE ASIA VIRTUAL TOUR',
          text: 'Au 1er semestre (7&nbsp;opérations) et l’<span class="autumn">Automne asiatique</span> au 2e&nbsp;semestre (10&nbsp;opérations) ont rassemblé 159 établissements français sur des formats virtuels.',
        },
        {
          title: 'CHOOSE FRANCE TOUR INDE, FÉVRIER',
          text: 'Ce salon Inde virtuel réunit une quarantaine d’établissements français qui ont pu s’adresser à près de 2&nbsp;700 visiteurs.',
        },
        {
          title: 'WEBINAIRE ATELIER CHINE, JUNE',
          text: 'Ce 5e Atelier Chine a permis d’apporter à la centaine d’établissements participants des informations sur la stratégie de l’Ambassade de France en Chine.',
        },
        {
          title: 'CHOOSE FRANCE - EDUCATION AND CAREER FAIR ASEAN, NOVEMBRE',
          text: 'Ce premier salon régional virtuel organisé en Asie a rassemblé 9 pays de la zone Indopacifique (Cambodge, Indonésie, Laos, Malaisie, Myanmar, Philippines, Singapour, Thaïlande et Vietnam), 43 établissements français et plus de 2&nbsp;500 étudiants.',
          image: 'asia1.png',
        },
      ],
      column2: [
        {
          title: "CONSEIL D'ORIENTATION STRATÉGIQUE INDE, NOVEMBRE",
          text: 'Organisé en présentiel, ce COS a permis de décliner localement la stratégie Indopacifique de la France, dont l’Inde est un pilier majeur, avec un objectif de doublement de la mobilité étudiante indienne vers la France (environ 8&nbsp;000 étudiants aujourd’hui).',
          image: 'asia2.jpg',
          image2: 'asia3.jpg',
        },
      ],
    },
    cafe: {
      title: 'LES CAFÉS-CROISSANTS',
      image: 'cafe-croissant-map.svg',
    },
    europa: {
      title: 'L’Europe',
      column1: [
        {
          text: 'L’année 2021 a été marquée par un effort important de promotion sur la zone Europe avec 11 événements de promotion. Cette collaboration s’est poursuivie avec un atelier Erasmus&nbsp;+ aux Rencontres Campus France et une participation de l’agence européenne au webinaire de la commission Recherche du Forum ou encore au séminaire Europe de Campus France à Budapest.',
        },
        {
          title: 'LA TOURNÉE VIRTUELLE DES BALKANS, DÉCEMBRE',
          text: 'Cette 2e édition répond à la volonté de renforcer la mobilité des étudiants vers la France en assurant une visibilité maximale des bourses, à travers plus de 80 webinaires.',
        },
      ],
      imgSrc1: 'europa1.jpg',
      imgSrc2: 'europa2.jpg',
    },
  },
  menu: [
    {
      title: 'Rendez-vous en France',
      anchor: 'intro',
    },
    {
      title: 'Janvier',
      anchor: 'janvier',
    },
    {
      title: 'Février',
      anchor: 'fevrier',
    },
    {
      title: 'Mars',
      anchor: 'mars',
    },
    {
      title: 'Avril',
      anchor: 'avril',
    },
    {
      title: 'Mai',
      anchor: 'mai',
    },
    {
      title: 'Juin',
      anchor: 'juin',
    },
    {
      title: 'Juillet',
      anchor: 'juillet',
    },
    {
      title: 'Août',
      anchor: 'aout',
    },
    {
      title: 'Septembre',
      anchor: 'septembre',
    },
    {
      title: 'Octobre',
      anchor: 'octobre',
    },
    {
      title: 'Novembre',
      anchor: 'novembre',
    },
    {
      title: 'Décembre',
      anchor: 'decembre',
    },
  ],
};
