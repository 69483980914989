import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B09Septembre extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b09septembre;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderIconTextSection = this.renderIconTextSection.bind(this);
    this.renderTextSection = this.renderTextSection.bind(this);
    this.renderStats = this.renderStats.bind(this);
    this.renderStatPart = this.renderStatPart.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  renderTextSection(options = {}, index = -1) {
    const { classes } = this.props;
    const { icon, title, content, image } = options;
    return (
      <Stack
        key={index}
        sx={{ width: { sm: '100%', md: options.width || '50%' }, pb: '40px', pl: '16px', pr: '16px' }}
        direction="column"
        spacing={2}
      >
        {icon && <img className={classes.icon} src={`${this.imgBase}${icon}`} alt="" />}
        {title && (
          <Typography key="title" className={classes.color}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
        {image && <img className={classes.illustration} src={`${this.imgBase}${image}`} alt="" />}
      </Stack>
    );
  }

  renderIconTextSection(options = {}) {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack direction="column" spacing={3}>
          {options.title && (
            <Typography className={classes.color} variant="h4" align="center">
              {parse(options.title)}
            </Typography>
          )}
          {options.subtitle && (
            <Typography>
              <b>{parse(options.subtitle)}</b>
            </Typography>
          )}
          {options.content && Array.isArray(options.content) && (
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {options.content.map((c, idx) => this.renderTextSection(c, idx))}
            </Stack>
          )}
        </Stack>
      </Container>
    );
  }

  renderStatPart(title, content) {
    const { classes } = this.props;
    return (
      <Box className={classes.statContainer}>
        <Typography className={classes.statTitle} align="center">
          <b>{parse(title)}</b>
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems="flex-start"
          justifyContent="space-around"
          spacing={4}
        >
          {content.map((c, idx) => (
            <Stack
              key={idx}
              className={classes.statContent}
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <Typography variant="h5">{parse(c.title)}</Typography>
              <Typography>{parse(c.desc)}</Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  }

  renderStats() {
    const { classes } = this.props;
    return (
      <Box sx={{ width: '100%' }} className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack direction="column" alignItems="center" spacing={4} sx={{ pl: '24px', pr: '24px', pb: '50px' }}>
            <Typography sx={{ width: '70%', marginBottom: 2 }} variant="h6" align="center">
              {parse(this.dataBlock.statsTitle)}
            </Typography>
            {this.renderStatPart(this.dataBlock.statsStudentTitle, this.dataBlock.statsStudentContent)}
            {this.renderStatPart(this.dataBlock.statsDoctorTitle, this.dataBlock.statsDoctorContent)}
          </Stack>
        </Container>
      </Box>
    );
  }

  renderLabel() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Typography sx={{ marginBottom: 4 }} className={classes.color} variant="h4" align="center">
          {this.dataBlock.labelTitle}
        </Typography>
        <Hidden mdDown>
          <Stack direction="row" alignItems="center" spacing={4}>
            <a href={this.dataBlock.labelImageUrl} className={classes.imageContainer} target="_blank">
              <img src={`${this.imgBase}${this.dataBlock.labelImage}`} className={classes.labelImage} alt="" />
            </a>
            <Stack
              direction="column"
              alignItems="flex-start"
              sx={{ width: { sm: '100%', md: '50%' }, pb: '40px', pl: '16px', pr: '16px' }}
              spacing={4}
            >
              <img className={classes.labelPatch} src={`${this.imgBase}${this.dataBlock.labelPatchImage}`} alt="" />
              <Typography className={classes.labelContent}>{this.dataBlock.labelText}</Typography>
            </Stack>
          </Stack>
        </Hidden>
        <Hidden lgUp>
          <Stack
            direction="column"
            alignItems="flex-start"
            sx={{ width: { sm: '100%', md: '50%' }, pb: '40px', pl: '16px', pr: '16px' }}
            spacing={4}
          >
            <Stack direction="row" alignItems="flex-end" spacing={4}>
              <a href={this.dataBlock.labelImageUrl} className={classes.imageContainer} target="_blank">
                <img src={`${this.imgBase}${this.dataBlock.labelImage}`} className={classes.labelImage} alt="" />
              </a>
              <img className={classes.labelPatch} src={`${this.imgBase}${this.dataBlock.labelPatchImage}`} alt="" />
            </Stack>
            <Typography className={classes.labelContent}>{this.dataBlock.labelText}</Typography>
          </Stack>
        </Hidden>
        <Hidden smDown>
          <a href={this.dataBlock.labelBtnUrl} className={classes.labelBtn} target="_blank">
            <Button variant="contained" disableElevation className={classes.btn}>
              {parse(this.dataBlock.labelBtn)}
            </Button>
          </a>
        </Hidden>
      </Container>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderIconTextSection({
          title: this.dataBlock.franceTitle,
          content: [
            {
              icon: this.dataBlock.icon,
              title: this.dataBlock.francePoliticTitle,
              content: this.dataBlock.francePoliticContent,
            },
            {
              icon: this.dataBlock.icon,
              title: this.dataBlock.franceRentreeTitle,
              content: this.dataBlock.franceRentreeContent,
              image: this.dataBlock.franceRentreeImage,
            },
          ],
        })}
        {this.renderStats()}
        {this.renderIconTextSection({
          content: [
            {
              icon: this.dataBlock.icon,
              title: this.dataBlock.moreInfoLeftTitle,
              content: this.dataBlock.moreInfoLeftContent,
            },
            {
              icon: this.dataBlock.icon,
              title: this.dataBlock.moreInfoRightTitle,
              content: this.dataBlock.moreInfoRightContent,
            },
          ],
        })}
        {this.renderLabel()}
      </Box>
    );
  }
}

B09Septembre.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B09Septembre);
