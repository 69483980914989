export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
    '& .color': {
      color: theme.palette.purple.dark,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    '& a': {
      color: theme.palette.purple.dark,
      textDecoration: 'none',
      transition: 'color 0.3s ease-in-out',
      '&:hover': {
        color: theme.palette.purple.light,
      },
    },
  },
  icon: {
    width: 76,
    height: 80,
    [theme.breakpoints.down('md')]: {
      width: 55,
      height: 55,
    },
  },
  image: {
    boxShadow: '1px 1px 6px 0px rgba(0,0,0,0.5)',
    width: '80%',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  imagesCrossed: {
    '& img': {
      position: 'relative',
      width: '100%',
    },
    '& > a:last-child img': {
      right: '20px',
      top: '15px',
    },
    '& > a:first-child img': {
      left: '30px',
    },
  },
  left: {
    transform: 'rotate(5deg)',
  },
  right: {
    transform: 'rotate(-5deg)',
  },
  color: {
    color: theme.palette.purple.dark,
  },
  colorBackground: {
    backgroundColor: theme.palette.purple.light,
    color: theme.palette.purple.contrastText,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  illustration: {
    width: '70%',
    display: 'block',
    margin: 'auto !important',
    maxHeight: 400,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: 250,
    },
  },
});
