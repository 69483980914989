export default theme => ({
  blockContainer: {
    position: 'relative',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  imgContainer: {
    position: 'relative',
  },
  main: {
    marginBottom: theme.spacing(32),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  pink: {
    color: theme.palette.pink.main,
  },
  pinkBackground: {
    backgroundColor: theme.palette.pink.ultralight,
    color: theme.palette.pink.contrastText,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '70px',
      paddingTop: '50px',
    },
  },
  bottomContent: {
    '& b': {
      textDecoration: 'underline',
    },
  },
  image: {
    position: 'absolute',
    left: theme.spacing(3),
    top: 'calc(100% - 100px)',
    width: 'calc(50% - 24px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      position: 'initial',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  mobilePadding: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  logo: {
    maxWidth: 80,
    objectFit: 'contain',
  },
});
