function loop(theme, res, list, type) {
  const suffixes = {
    main: '',
    light: 'light',
    ultralight: 'ultralight',
    dark: 'dark',
    ultradark: 'ultradark',
    medium: 'medium',
    bg: 'bg',
    apple: 'apple',
  };
  const suffixe = suffixes[type];
  list.forEach(key => {
    res[`${key}${suffixe}`] = {
      backgroundColor: theme.palette[key][type],
      color: theme.palette[key].contrastText,
    };
  });
}

export default theme => {
  const main = ['white', 'black', 'grey', 'blue', 'green', 'ocher', 'orange', 'pink', 'purple'];
  const light = ['grey', 'blue', 'green', 'ocher', 'orange', 'pink', 'purple'];
  const ultralight = ['blue', 'green', 'ocher', 'pink'];
  const dark = ['blue', 'green', 'ocher', 'pink', 'purple'];
  const ultradark = ['blue', 'green', 'purple'];
  const medium = ['blue'];
  const bg = ['blue'];
  const apple = ['green'];

  const res = {
    blockContainer: {
      marginBottom: -100,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 'initial !important',
        paddingRight: 'initial !important',
        marginBottom: 0,
      },
    },
    bg: {
      position: 'relative',
      width: '100%',
      fontSize: 0,
      '& img': {
        width: '100%',
      },
    },
    title: {
      position: 'absolute',
      bottom: -33,
      color: theme.palette.white.main,
      left: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        left: theme.spacing(4),
        bottom: -13,
        fontSize: '4rem !important',
      },
    },
    textBottom: {
      position: 'relative',
      zIndex: 5,
      top: 132,
      left: 'initial',
      right: 0,
      [theme.breakpoints.down('md')]: {
        top: 46,
      },
    },
    textBottomBig: {
      position: 'absolute',
      zIndex: 5,
      right: -5,
      top: -18,
      color: theme.palette.white.main,
      [theme.breakpoints.down('md')]: {
        top: -7,
        right: 5,
        fontSize: '4rem !important',
      },
    },
    coloredSquare: {
      position: 'relative',
      height: 270,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        height: 100,
      },
    },
    quoteIcon: {
      position: 'absolute',
      top: -35,
      left: theme.spacing(4),
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    desc: {
      position: 'relative',
      top: -100,
      left: theme.spacing(8),
      maxWidth: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        top: 'initial',
        left: 'initial',
        maxWidth: 'initial',
        paddingTop: theme.spacing(6),
      },
    },
    backgroundAnimationWrapper: {
      overflow: 'hidden',
      height: 450,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.white.main,
      [theme.breakpoints.down('md')]: {
        height: 162,
      },
    },
    backgroundAnimationContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      transform: 'rotate(-6deg) scale(1.2)',
    },
    backgroundAnimationContent: {
      width: 'calc(100% / 3)',
      height: 175,
      padding: 8,
      '& > img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      transform: 'scale(0)',
      transitionProperty: 'transform',
      transitionDuration: '1s',
      transitionTimingFunction: 'ease-in-out',
      '&.active': {
        transform: 'scale(1)',
      },
      [theme.breakpoints.down('md')]: {
        height: 100,
      },
    },
  };

  loop(theme, res, main, 'main');
  loop(theme, res, light, 'light');
  loop(theme, res, dark, 'dark');
  loop(theme, res, ultralight, 'ultralight');
  loop(theme, res, ultradark, 'ultradark');
  loop(theme, res, medium, 'medium');
  loop(theme, res, bg, 'bg');
  loop(theme, res, apple, 'apple');

  return res;
};
