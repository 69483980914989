export default theme => ({
  container: {
    position: 'fixed',
    zIndex: 1100,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'visible',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    backgroundColor: theme.palette.blue.ultradark,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(2),
  },
  months: {
    width: '100%',
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
  entry: {
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    '&:first-child': {
      gridColumn: '1 / span 2',
    },
  },
  active: {
    '& p': {
      textDecoration: 'underline',
    },
  },
  label: {
    backgroundColor: 'rgba(18, 47, 121, 0.5)',
  },
  link: {
    position: 'relative',
    textTransform: 'uppercase',
    color: theme.palette.white.main,
    width: 'max-content',
  },
  buttonPDF: {
    backgroundColor: `${theme.palette.blue.medium} !important`,
  },
});
