import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Hidden from '@mui/material/Hidden';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import VideoDialog from '../../popups/VideoDialog';
import { ReactComponent as Play } from '../../res/playRounded.svg';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B12Decembre extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoOpen: false,
    };

    this.dataBlock = data.blocks.b12decembre;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
  }

  renderTopPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Typography sx={{ marginBottom: 4 }} className={classes.color} variant="h5" align="center">
          {this.dataBlock.title}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={4}
        >
          <Box sx={{ width: { xs: '100%', md: '45%' } }} className={classes.tableauBluePart}>
            <Typography sx={{ pb: 4 }}>{parse(this.dataBlock.tableauBluePartText)}</Typography>
            <Stack direction="row" sx={{ pb: 2 }} spacing={2}>
              <img className={classes.icon} src={`${this.imgBase}${this.dataBlock.tableauBluePartImageText1}`} alt="" />
              <Stack direction="column">
                {this.dataBlock.tableauBluePartText1.map((c, idx) => (
                  <Typography sx={{ pb: 1 }} key={idx}>
                    {parse(c)}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <img className={classes.icon} src={`${this.imgBase}${this.dataBlock.tableauBluePartImageText2}`} alt="" />
              <Stack direction="column">
                {this.dataBlock.tableauBluePartText2.map((c, idx) => (
                  <Typography sx={{ pb: 1 }} key={idx}>
                    {parse(c)}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <img
              className={classes.illustration}
              src={`${this.imgBase}${this.dataBlock.tableauBluePartImage}`}
              alt=""
            />
          </Box>
          <Hidden mdDown>
            <Box sx={{ flex: 1 }}></Box>
          </Hidden>
        </Stack>
        <Hidden mdDown>
          <Stack
            className={classes.seminaireImageContainer}
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ width: { xs: '100%', md: '55%' } }}>
              <img className={classes.image} src={`${this.imgBase}${this.dataBlock.seminaireRedPartImage}`} alt="" />
            </Box>
          </Stack>
        </Hidden>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Box sx={{ width: { xs: '100%', md: '55%' } }} className={classes.seminaireRedPart}>
            <Typography>{parse(this.dataBlock.seminaireRedPartText)}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
        </Stack>
        <Hidden lgUp>
          <Stack
            className={classes.seminaireImageContainer}
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ width: { xs: '100%', md: '55%' } }}>
              <img className={classes.image} src={`${this.imgBase}${this.dataBlock.seminaireRedPartImage}`} alt="" />
            </Box>
          </Stack>
        </Hidden>
        <Stack
          sx={{ position: 'relative' }}
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
        >
          <img
            className={`${classes.soutienCocacImg} ${classes.image}`}
            src={`${this.imgBase}${this.dataBlock.soutienImage}`}
            alt=""
          />
          <Hidden lgUp>
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ width: { xs: '100%', md: '65%' } }} className={classes.diplomatieRedPart}>
              {this.dataBlock.diplomatieRedPartContent.map((c, idx) => (
                <Typography key={idx}>{parse(c)}</Typography>
              ))}
              <Button sx={{ marginTop: 4 }} variant="contained" disableElevation className={`${classes.btn}`}>
                <a
                  href={this.dataBlock.diplomatieRedPartButtonUrl}
                  alt={this.dataBlock.diplomatieRedPartButtonText}
                  target="_blank"
                >
                  {parse(this.dataBlock.diplomatieRedPartButtonText)}
                </a>
              </Button>
            </Box>
          </Hidden>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ width: { xs: '100%', md: '45%' } }} className={classes.tableauBluePart}>
            <Typography>{parse(this.dataBlock.soutienBluePartText)}</Typography>
          </Box>
        </Stack>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Hidden mdDown>
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ width: { xs: '100%', md: '65%' } }} className={classes.diplomatieRedPart}>
              {this.dataBlock.diplomatieRedPartContent.map((c, idx) => (
                <Typography key={idx}>{parse(c)}</Typography>
              ))}
              <Button sx={{ marginTop: 4 }} variant="contained" disableElevation className={`${classes.btn}`}>
                <a
                  href={this.dataBlock.diplomatieRedPartButtonUrl}
                  alt={this.dataBlock.diplomatieRedPartButtonText}
                  target="_blank"
                >
                  {parse(this.dataBlock.diplomatieRedPartButtonText)}
                </a>
              </Button>
            </Box>
          </Hidden>
        </Stack>
      </Container>
    );
  }

  renderMiddlePart() {
    const { classes } = this.props;
    const { videoOpen } = this.state;
    return (
      <Container maxWidth="md" className={`${classes.blockContainer} ${classes.videoBlueBlock}`}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Box sx={{ width: { xs: '100%', md: '65%' } }} className={classes.conseilBluePart}>
            <Typography>{parse(this.dataBlock.conseilBluePartText)}</Typography>
            <Button sx={{ marginTop: 4 }} variant="contained" disableElevation className={`${classes.btnBlue}`}>
              <a
                href={this.dataBlock.conseilBluePartButtonUrl}
                alt={this.dataBlock.conseilBluePartButtonText}
                target="_blank"
              >
                {parse(this.dataBlock.conseilBluePartButtonText)}
              </a>
            </Button>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box
            sx={{ width: { xs: '100%', md: '65%' } }}
            className={classes.videoContainer}
            onClick={() => this.setState({ videoOpen: true })}
          >
            <Play className={classes.play} />
            <img
              className={`${classes.conseilVideoImg} ${classes.image}`}
              src={`${this.imgBase}${this.dataBlock.conseilVideoPreviewImage}`}
              alt=""
            />
          </Box>
        </Stack>
        <VideoDialog
          open={videoOpen}
          url={this.dataBlock.conseilVideoUrl}
          onClose={() => this.setState({ videoOpen: false })}
        />
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={`${classes.blockContainer} ${classes.imgRedBlock}`}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Hidden lgUp>
            <Box sx={{ width: { xs: '100%', md: '45%' } }} className={classes.signatureRedPart}>
              <Typography>{parse(this.dataBlock.signatureRedPartText)}</Typography>
            </Box>
          </Hidden>
          <Box sx={{ width: { xs: '100%', md: '55%' } }}>
            <img className={classes.image} src={`${this.imgBase}${this.dataBlock.signatureRedPartImage}`} alt="" />
          </Box>
          <Hidden mdDown>
            <Box sx={{ width: { xs: '100%', md: '45%' } }} className={classes.signatureRedPart}>
              <Typography>{parse(this.dataBlock.signatureRedPartText)}</Typography>
            </Box>
          </Hidden>
        </Stack>
      </Container>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderMiddlePart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B12Decembre.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B12Decembre);
