export default theme => ({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  content: {
    padding: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  mainTitle: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.green.ultradark,
  },
  title: {
    color: theme.palette.green.ultradark,
    fontWeight: 'bold !important',
  },
  image: {
    width: '100%',
    objectFit: 'contain',
    paddingBottom: theme.spacing(2),
  },
});
