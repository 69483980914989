export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
    },
    '& a': {
      textDecoration: 'none !important',
    },
  },
  icon: {
    width: 76,
    height: 80,
    stroke: theme.palette.ocher.main,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: 55,
      height: 55,
    },
  },
  color: {
    color: theme.palette.blue.ultradark,
  },
  colorBackground: {
    backgroundColor: theme.palette.blue.light,
    color: theme.palette.white.main,
  },
  aside: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue.ultradark,
    '& b': {
      color: theme.palette.blue.light,
    },
    '& p': {
      color: theme.palette.white.main,
    },
  },
  text: {
    '& b': {
      color: theme.palette.blue.ultradark,
    },
  },
  btn: {
    borderRadius: `0 !important`,
    alignSelf: `flex-start !important`,
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.blue.light} !important`,
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      margin: `${theme.spacing(4)} auto auto auto !important`,
      display: 'block !important',
    },
  },
  boxedImg1: {
    maxWidth: 400,
    maxHeight: 80,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  boxedImg2: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  boxedImg3: {
    position: 'absolute',
    top: 'calc(100% + 25px)',
    right: 0,
    maxWidth: '140%',
    minWidth: '120%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      position: 'initial',
    },
  },
  illustration: {
    width: '60%',
    margin: 'auto',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  numbers: {
    [theme.breakpoints.down('md')]: {
      '& h5': {
        fontSize: '1.75rem',
      },
    },
  },
});
