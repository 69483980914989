export default theme => ({
  blockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),

    '& .dash': {
      color: theme.palette.blue.light,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'initial !important',
      paddingRight: 'initial !important',
      paddingBottom: 0,
      paddingTop: theme.spacing(2),
    },
  },
  secondaryBlockContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),

    '& .dash': {
      color: theme.palette.blue.light,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  icon: {
    width: 76,
    height: 80,
    stroke: theme.palette.blue.main,
    [theme.breakpoints.down('md')]: {
      width: 55,
      height: 55,
    },
  },
  color: {
    color: theme.palette.blue.medium,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  colorWhite: {
    color: theme.palette.white.main,
  },
  colorBackground: {
    backgroundColor: theme.palette.blue.medium,
    color: theme.palette.blue.contrastText,
  },
  colorBackgroundDark: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.blue.contrastText,
  },
  btn: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.blue.dark} !important`,
    color: `${theme.palette.blue.contrastText} !important`,
    '& a': {
      color: theme.palette.blue.contrastText,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      margin: 'auto !important',
      display: 'block !important',
      marginTop: '50px !important',
    },
  },
  btnInvert: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.blue.dark} !important`,
    '& a': {
      color: theme.palette.blue.dark,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      margin: 'auto !important',
      display: 'block !important',
      marginTop: `${theme.spacing(4)} !important`,
    },
  },
  boxTop: {
    padding: theme.spacing(6),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(4),
    },
  },
  videoImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  play: {
    width: 171,
    height: 171,
    position: 'absolute',
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
    },
    '& path': {
      stroke: theme.palette.white.main,
      transition: 'stroke 0.3s ease-in-out',
    },
  },
  topVideo: {
    width: '60%',
    height: 406,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    left: `-${theme.spacing(8)}`,
    top: `-${theme.spacing(8)}`,
    marginTop: theme.spacing(8),
    backgroundColor: 'grey',
    '&:hover path': {
      stroke: `${theme.palette.blue.dark} !important`,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      left: 0,
      top: 0,
      marginTop: 0,
      maxHeight: 250,
    },
  },
  boxBottom: {
    width: '60%',
    marginLeft: 'auto',
    padding: theme.spacing(6),
    '& button': {
      marginTop: theme.spacing(2),
      marginLeft: '40%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(4),
    },
  },
  bottomVideo: {
    width: '60%',
    height: 406,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: `-${theme.spacing(8)}`,
    backgroundColor: 'grey',
    '&:hover path': {
      stroke: `${theme.palette.blue.dark} !important`,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: 250,
      top: 0,
      marginBottom: theme.spacing(16),
    },
  },
  catalogueBoxContainer: {
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    marginBottom: theme.spacing(12),
    position: 'relative',
    backgroundColor: theme.palette.blue.dark,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  catalogueBoxContent: {
    width: '100%',
    border: '1px solid',
    padding: '40px 70px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '40px 20px',
      paddingBottom: theme.spacing(4),
      '& p': {
        marginTop: '0 !important',
      },
    },
  },
  catalogueBoxContentTitle: {
    width: '100%',
    position: 'absolute',
    top: -11,
    left: 0,
    '& > b': {
      backgroundColor: theme.palette.blue.dark,
      padding: '0 20px',
      [theme.breakpoints.down('md')]: {
        padding: '0 10px',
        display: 'block',
        margin: 'auto',
        width: '80%',
      },
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '0.8rem',
    },
  },
  catalogueBoxContentText: {
    width: '25%',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& h5': {
        fontSize: '1.75rem',
      },
    },
  },
  catalogueWhiteBox: {
    width: '60%',
    position: 'absolute',
    left: 0,
    padding: theme.spacing(8),
    backgroundColor: theme.palette.white.main,
    color: theme.palette.white.contrastText,

    '& .blue': {
      color: theme.palette.blue.medium,
    },

    [theme.breakpoints.down('md')]: {
      width: `calc(100% + ${theme.spacing(8)})`,
      position: 'relative',
      padding: theme.spacing(4),
      marginLeft: `-${theme.spacing(4)}`,
      marginTop: theme.spacing(4),
    },
  },
  image: {
    width: 132,
    height: 198,
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
});
