import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Stack, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PopupPage from '../PopupPage';

import styles from './styles';

class PopupCredits extends React.Component {
  renderTextSection(content, index, columnWidth = '50%') {
    const { classes } = this.props;
    return (
      <Stack key={index} sx={{ width: { sm: '100%', md: columnWidth } }} direction="column" spacing={2}>
        {typeof content === 'string' && (
          <Typography key="content" variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography
              key={idx}
              variant="body1"
              sx={{ mt: '0 !important', mb: '16px !important' }}
              className={classes.heading}
            >
              {parse(c)}
            </Typography>
          ))}
      </Stack>
    );
  }

  render() {
    const { popupData, classes, open, onClose } = this.props;
    return (
      <PopupPage key="credits" open={open} onClose={onClose} closeIconColor="blue" className={classes.container}>
        <Stack key="stack01" direction="column" className={classes.content}>
          <Typography key="title" variant="h5" align="center" className={classes.mainTitle}>
            <b>{parse(popupData.title)}</b>
          </Typography>
          <Stack
            key="text"
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
          >
            {this.renderTextSection(popupData.content, 'content', '100%')}
          </Stack>
        </Stack>
      </PopupPage>
    );
  }
}

PopupCredits.propTypes = {
  classes: PropTypes.object.isRequired,
  popupData: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(PopupCredits);
