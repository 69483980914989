import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import PopupCEO from '../../popups/PopupCEO';
import Anchor from '../../components/Anchor';

import parse from '../../utils/parse';
import styles from './styles';
import data from '../../utils/data';

class B00Intro extends React.Component {
  constructor(props) {
    super(props);
    this.dataBlock = data.blocks.b00intro;
    this.dataPopup = data.popups.ceo;
    this.imgBase = `${this.props.baseUrl}/blocks/`;
    this.handleScroll = this.handleScroll.bind(this);
    this.lastScrollPosition = 0;

    this.state = {
      rotation: 0,
    };
  }

  componentDidMount() {
    this.htmlNode = document.querySelector('html');
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const diff = this.lastScrollPosition - this.htmlNode.scrollTop;
    this.lastScrollPosition = this.htmlNode.scrollTop;
    const incr = diff === 0 ? 0 : (diff / Math.abs(diff)) * 1.5;
    if (incr !== 0) {
      this.setState(prev => ({ rotation: (prev.rotation + incr) % 360 }));
    }
  }

  renderHeader() {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.headerWrapper}>
          <img className="desktop" src={`${this.imgBase}${this.dataBlock.headerImg}`} alt="" />
          <img className="mobile" src={`${this.imgBase}${this.dataBlock.headerImgMobile}`} alt="" />
          <Typography variant="subtitle2" className={classes.headerTitle}>
            {parse(this.dataBlock.headerTitle)}
          </Typography>
        </div>
      </div>
    );
  }

  renderEdito() {
    const { classes, onPopup } = this.props;
    const { rotation } = this.state;
    return (
      <div className={classes.edito}>
        <div className={classes.rv}>
          <Typography variant="h3" className={`${classes.ocher} ${classes.rvTitle1}`}>
            {parse(this.dataBlock.title1)}
          </Typography>
          <Typography variant="h2" className={`${classes.blue} ${classes.rvTitle2}`}>
            {parse(this.dataBlock.title2)}
          </Typography>
          <img src={`${this.imgBase}${this.dataBlock.rvImg1}`} alt="" className={classes.rvIllus1} />
          <img src={`${this.imgBase}${this.dataBlock.rvImg2}`} alt="" className={classes.rvIllus2} />
        </div>
        <div className={classes.quote}>
          <div className={classes.quotecontent}>
            <Typography variant="button" className={`${classes.ocher} ${classes.quoteAuthor}`}>
              {parse(this.dataBlock.quoteAuthor)}
            </Typography>
            <Typography variant="subtitle1" className={classes.blue}>
              {parse(this.dataBlock.quote)}
            </Typography>
            <Button
              variant="contained"
              disableElevation
              className={classes.quoteBtn}
              onClick={() => onPopup(this.dataBlock.quotePopupTrigger, true)}
            >
              {parse(this.dataBlock.quoteBtn)}
            </Button>
          </div>
          <img
            src={`${this.imgBase}${this.dataBlock.quoteLogo}`}
            alt=""
            className={classes.quoteLogo}
            style={{ color: 'red', transform: `translateY(-60%) rotate(${rotation}deg)` }}
          />
          <span className={classes.quoteImgContainer}>
            <img src={`${this.imgBase}${this.dataBlock.quoteImg}`} alt="" className={classes.quoteImg} />
          </span>
        </div>
      </div>
    );
  }

  renderTenYears() {
    const { classes } = this.props;
    const { rotation } = this.state;
    return (
      <div className={classes.tenYears}>
        <div className={classes.tenYearsImg1Wrapper}>
          <img src={`${this.imgBase}${this.dataBlock.tenYearsImg1}`} alt="" className={classes.tenYearsImg1} />
        </div>
        <div className={classes.tenYearsText}>
          <Typography variant="h4" className={`${classes.ocher} ${classes.tenYearsTitle1}`}>
            {parse(this.dataBlock.tenYearsTitle1)}
          </Typography>
          <Typography variant="h4" className={`${classes.blue} ${classes.tenYearsTitle2}`}>
            {parse(this.dataBlock.tenYearsTitle2)}
          </Typography>
        </div>
        <div className={classes.tenYearsImg2Wrapper}>
          <img src={`${this.imgBase}${this.dataBlock.tenYearsImg2}`} alt="" className={classes.tenYearsImg2} />
          <img
            src={`${this.imgBase}${this.dataBlock.tenYearsLogo}`}
            alt=""
            className={classes.tenYearsLogo}
            style={{ color: 'red', transform: `translate(80%, -60%) rotate(${rotation}deg)` }}
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg" className={classes.container}>
        {this.renderEdito()}
        {this.renderTenYears()}
      </Container>
    );
  }

  render() {
    const { classes, baseUrl, onPopup, popupsState } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Anchor id="intro" />
        {this.renderHeader()}
        {this.renderContent()}
        <PopupCEO
          popupData={this.dataPopup}
          open={!!popupsState[this.dataBlock.quotePopupTrigger]}
          baseUrl={baseUrl}
          color="bluedark"
          onClose={() => onPopup(this.dataBlock.quotePopupTrigger, false)}
        ></PopupCEO>
      </div>
    );
  }
}

B00Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  onPopup: PropTypes.func,
  popupsState: PropTypes.object,
};

export default withStyles(styles)(B00Intro);
