export default theme => ({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    backgroundColor: theme.palette.blue.dark,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
  },
  titleText: {
    padding: theme.spacing(4, 6, 6, 6),
    marginTop: theme.spacing(4),
    '& > *:first-child': {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    color: theme.palette.blue.dark,
  },
  image: {
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(4),
    transform: 'translateY(50%)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      position: 'initial',
      transform: 'initial',
      padding: theme.spacing(2),
    },
  },
  asideBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 6, 6, 6),
    backgroundColor: theme.palette.blue.dark,
    '& > *:first-child': {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  desc: {
    padding: theme.spacing(8),
    display: 'flex',
    '& > *': {
      width: '100%',
    },
    '& b': {
      color: theme.palette.blue.dark,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      gap: theme.spacing(2),
    },
  },
  descLeft: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  descRight: {
    paddingLeft: theme.spacing(4),
    '& > *:first-child': {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
});
