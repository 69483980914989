import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import VideoDialog from '../../popups/VideoDialog';
import { ReactComponent as Play } from '../../res/playRounded.svg';

import styles from './styles';
import data from '../../utils/data';
import parse from '../../utils/parse';

class B11Novembre extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoOpen: false,
    };

    this.dataBlock = data.blocks.b11novembre;
    this.imgBase = `${this.props.baseUrl}/blocks/`;

    this.renderTopPart = this.renderTopPart.bind(this);
    this.renderMiddlePart = this.renderMiddlePart.bind(this);
    this.renderBottomPart = this.renderBottomPart.bind(this);
    this.renderTextSection = this.renderTextSection.bind(this);
  }

  renderTextSection(options = {}, key = -1) {
    const { classes } = this.props;
    const { icon, title, content, image, color } = options;
    const typoClass = color ? classes.color : null;
    return (
      <Stack
        key={key}
        sx={{ width: { sm: '100%', md: '100%' } }}
        direction="column"
        alignItems="flex-start"
        spacing={2}
      >
        {icon && <img className={classes.icon} src={`${this.imgBase}${icon}`} alt="" />}
        {title && (
          <Typography key={title} className={typoClass}>
            <b>{parse(title)}</b>
          </Typography>
        )}
        {typeof content === 'string' && (
          <Typography key={content} variant="body1">
            {parse(content)}
          </Typography>
        )}
        {Array.isArray(content) &&
          content.map((c, idx) => (
            <Typography key={idx} variant="body1">
              {parse(c)}
            </Typography>
          ))}
        {image && <img className={classes.illustration} src={`${this.imgBase}${image}`} alt="" />}
      </Stack>
    );
  }

  renderTopPart() {
    const { classes } = this.props;
    const { videoOpen } = this.state;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Typography variant="h5" align="center" className={classes.title}>
          {parse(this.dataBlock.title)}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          justifyContent="space-between"
          spacing={4}
          sx={{ pl: 0, pr: 0 }}
        >
          <Stack sx={{ width: { sm: '100%', md: '45%' } }} direction="column" spacing={2}>
            <img className={classes.image} src={`${this.imgBase}${this.dataBlock.tenYearImage}`} alt="" />
            <Box className={classes.campusText}>
              <Typography>{parse(this.dataBlock.tenYearText)}</Typography>
            </Box>
          </Stack>
          <Box className={classes.videoContainer} onClick={() => this.setState({ videoOpen: true })}>
            <Play className={classes.play} />
            <img className={classes.videoImage} src={`${this.imgBase}${this.dataBlock.videoPreviewImage}`} alt="" />
          </Box>
        </Stack>
        <VideoDialog
          open={videoOpen}
          url={this.dataBlock.videoUrl}
          onClose={() => this.setState({ videoOpen: false })}
        />
      </Container>
    );
  }

  renderMiddlePart() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.blockContainer}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{ pr: '30px', pl: '30px', pb: '50px' }}
          justifyContent="space-between"
          spacing={4}
        >
          {this.renderTextSection(
            {
              title: this.dataBlock.interviewTitle,
              content: this.dataBlock.interviewText,
              color: true,
              image: this.dataBlock.interviewImage,
            },
            'interview',
          )}
          <Stack direction="column" width="100%" alignItems="flex-start" className={classes.partners}>
            {this.renderTextSection(
              {
                title: this.dataBlock.partnersTitle,
                color: true,
              },
              this.dataBlock.partnersText1,
            )}
            {this.renderTextSection(
              {
                icon: this.dataBlock.partnersIcon1,
                content: this.dataBlock.partnersText1,
              },
              this.dataBlock.partnersText2,
            )}
            {this.renderTextSection(
              {
                icon: this.dataBlock.partnersIcon2,
                content: this.dataBlock.partnersText2,
              },
              this.dataBlock.partnersText3,
            )}
            {this.renderTextSection(
              {
                icon: this.dataBlock.partnersIcon3,
                content: this.dataBlock.partnersText3,
              },
              this.dataBlock.partnersText4,
            )}
            {this.renderTextSection(
              {
                icon: this.dataBlock.partnersIcon4,
                content: this.dataBlock.partnersText4,
              },
              'partners',
            )}
          </Stack>
        </Stack>
      </Container>
    );
  }

  renderBottomPart() {
    const { classes } = this.props;
    return (
      <Box className={classes.colorBackground}>
        <Container maxWidth="md" className={classes.blockContainer}>
          <Stack sx={{ p: '50px 30px' }} direction="column" spacing={2}>
            <Typography className={classes.white} variant="h6">
              {parse(this.dataBlock.meetTitle)}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
              spacing={4}
            >
              {this.renderTextSection(
                {
                  content: this.dataBlock.meetLeftText,
                },
                'left',
              )}
              {this.renderTextSection(
                {
                  content: this.dataBlock.meetRightText,
                },
                'right',
              )}
            </Stack>
            <img className={classes.illustrationBottom} src={`${this.imgBase}${this.dataBlock.meetImage}`} alt="" />
          </Stack>
        </Container>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.white.main }} className={classes.blockContainer}>
        {this.renderTopPart()}
        {this.renderMiddlePart()}
        {this.renderBottomPart()}
      </Box>
    );
  }
}

B11Novembre.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(styles)(B11Novembre);
